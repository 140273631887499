define('milegacy/routes/account/settings/deactivate', ['exports', 'ember', 'milegacy/routes/base', 'milegacy/utils/unwrap'], function (exports, _ember, _milegacyRoutesBase, _milegacyUtilsUnwrap) {
  exports['default'] = _milegacyRoutesBase['default'].extend({
    'page:title': 'Deactivate Account',
    'page:banner': 'Deactivate Account',
    'page:menu': _ember['default'].computed.alias('menu'),
    model: function model() {
      this.set('menu', _ember['default'].Object.create({
        text: 'Deactivate Account',
        icon: 'settings',
        iconColor: 'gray'
      }));

      var user = this.get('session.currentUser');

      return _ember['default'].Object.create({
        user: user
      });
    },
    actions: {
      deactivate: function deactivate(password, user) {
        var _this = this;

        var flash = this.get('flashMessages');

        user = (0, _milegacyUtilsUnwrap.unwrap)(user);

        // Get user out of invalid state
        user.rollbackAttributes();

        this.store.adapterFor('user').authorize(password, function () {
          return user.destroyRecord();
        }).then(function () {
          flash.success(_this.get('i18n').t('flash.account.deactivate.success'));
          _this.get('session').invalidate();
        });
      },
      willTransition: function willTransition() {
        var user = this.get('controller.model.user');
        user = (0, _milegacyUtilsUnwrap.unwrap)(user);
        if (user) {
          // Get user out of invalid state
          user.rollbackAttributes();
        }
        return true;
      }
    }
  });
});