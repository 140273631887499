define('milegacy/persistors/story', ['exports', 'ember'], function (exports, _ember) {

  var Promise = _ember['default'].RSVP.Promise;

  exports['default'] = _ember['default'].Object.extend({
    isSaving: false,
    story: null,
    save: function save() {
      var story;
      return regeneratorRuntime.async(function save$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.set('isSaving', true);

            story = this.get('story');
            context$1$0.prev = 2;
            context$1$0.next = 5;
            return regeneratorRuntime.awrap(story.save());

          case 5:
            context$1$0.next = 10;
            break;

          case 7:
            context$1$0.prev = 7;
            context$1$0.t0 = context$1$0['catch'](2);
            throw context$1$0.t0;

          case 10:
            context$1$0.prev = 10;

            this.set('isSaving', false);
            return context$1$0.finish(10);

          case 13:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this, [[2, 7, 10, 13]]);
    },
    saveMedia: function saveMedia() {
      var media, promises;
      return regeneratorRuntime.async(function saveMedia$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            media = this.get('story.media');
            promises = media.map(function callee$1$0(m) {
              return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                while (1) switch (context$2$0.prev = context$2$0.next) {
                  case 0:
                    context$2$0.next = 2;
                    return regeneratorRuntime.awrap(m.save());

                  case 2:
                    context$2$0.prev = 2;
                    context$2$0.next = 5;
                    return regeneratorRuntime.awrap(m.get('file').upload());

                  case 5:
                    m.set('status', 'uploaded');
                    context$2$0.next = 12;
                    break;

                  case 8:
                    context$2$0.prev = 8;
                    context$2$0.t0 = context$2$0['catch'](2);

                    // eslint-disable-next-line no-console
                    console.error(context$2$0.t0);
                    m.set('status', 'failed');

                  case 12:
                    context$2$0.prev = 12;
                    context$2$0.next = 15;
                    return regeneratorRuntime.awrap(m.save());

                  case 15:
                    return context$2$0.finish(12);

                  case 16:
                  case 'end':
                    return context$2$0.stop();
                }
              }, null, _this, [[2, 8, 12, 16]]);
            });
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(Promise.all(promises));

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    }
  });
});

// Save + upload files concurrently