define('milegacy/initializers/i18n', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(app) {
    ['component', 'controller', 'model', 'route', 'view'].forEach(function (type) {
      app.inject(type, 'i18n', 'service:i18n');
    });
  }

  exports['default'] = {
    name: 'i18n',
    after: 'ember-i18n',
    initialize: initialize
  };
});