define('milegacy/routes/milestones/index', ['exports', 'ember', 'milegacy/routes/public-base', 'milegacy/utils/countable', 'milegacy/mixins/story-uploader'], function (exports, _ember, _milegacyRoutesPublicBase, _milegacyUtilsCountable, _milegacyMixinsStoryUploader) {
  exports['default'] = _milegacyRoutesPublicBase['default'].extend(_milegacyMixinsStoryUploader['default'], {
    'page:menu': _ember['default'].computed.alias('menu'),
    newStory: function newStory() {
      var store = this.get('store');
      var user = this.modelFor('user').get('user');

      return store.createRecord('story', {
        user: user,
        audience: 'network'
      });
    },
    model: function model() {
      this.set('menu', _ember['default'].Object.create({
        text: 'All Stories',
        icon: 'all',
        iconColor: 'gray'
      }));

      var store = this.get('store');
      var userModel = this.modelFor('user');
      var user = userModel.get('user');
      var userId = userModel.get('userId');
      var friendship = userModel.get('friendship');

      var stories = (0, _milegacyUtilsCountable.countable)(store, 'story', ['userId'], { user_id: userId }, { isNew: false, isDeleted: false, userId: userId }, ['-createdAt']);

      return _ember['default'].Object.create({
        newStory: this.newStory(),
        stories: stories,
        user: user,
        friendship: friendship
      });
    }
  });
});