define('milegacy/components/search-result', ['exports', 'ember', 'milegacy/utils/unwrap'], function (exports, _ember, _milegacyUtilsUnwrap) {
  exports['default'] = _ember['default'].Component.extend({
    isCurrent: (function () {
      return (0, _milegacyUtilsUnwrap.unwrap)(this.get('current')) === (0, _milegacyUtilsUnwrap.unwrap)(this.get('result'));
    }).property('current', 'result'),
    linkParams: (function () {
      return _ember['default'].makeArray(this.get('link')).concat(_ember['default'].makeArray(this.get('models')));
    }).property('link', 'models'),
    page: _ember['default'].inject.service(),
    click: function click() {
      this.get('page').hideModals();
    }
  });
});