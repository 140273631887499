define('milegacy/components/user-group', ['exports', 'ember', 'milegacy/utils/countable', 'milegacy/utils/expand-filter', 'milegacy/utils/destroy-query', 'milegacy/utils/key-transform', 'npm:lodash/array/compact'], function (exports, _ember, _milegacyUtilsCountable, _milegacyUtilsExpandFilter, _milegacyUtilsDestroyQuery, _milegacyUtilsKeyTransform, _npmLodashArrayCompact) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var filterMap = {
    all: {},
    unread: {
      read: false
    },
    new_story: {
      read: false,
      kind: 'new_story'
    },
    follow_story: {
      kind: 'follow_story'
    },
    story_activity: {
      read: false,
      kind: 'story_activity'
    }
  };

  exports['default'] = _ember['default'].Component.extend({
    i18n: _ember['default'].inject.service(),
    classNameBindings: [':list-item'],
    kind: 'all',
    expanded: false,
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    user: _ember['default'].computed.alias('digest.group'),
    kindDigests: _ember['default'].computed.alias('digest.subdigests'),
    allNotifications: (function () {
      var store = this.get('store');
      return store.peekAll('notification');
    }).property(),
    friendRequest: (function () {
      var notifications = this.get('allNotifications');
      var userId = this.get('user.id');
      var currentUserId = this.get('session.currentUser.id');

      if (userId !== currentUserId) {
        var filter = (0, _milegacyUtilsExpandFilter['default'])({
          isNew: false,
          userId: currentUserId,
          notifierId: userId,
          kind: 'friend_request' });

        return notifications.filter(filter)[0];
      }
    }).property('allNotifications.[]'),
    unloadIfFriendRequestDeleted: (function () {
      if (this.get('friendRequest.isDeleted')) {
        this.unload();
      }
    }).observes('friendRequest.isDeleted'),
    hasFriendRequest: (function () {
      return !!this.get('friendRequest') && !this.get('friendRequest.isDeleted');
    }).property('friendRequest.deleted'),
    unload: function unload() {
      var digest = this.get('digest');
      digest.unloadRecord({ hasMany: true });
    },
    unread: _ember['default'].computed.not('digest.read'),
    countString: (function () {
      var i18n = this.get('i18n');
      var digests = this.get('kindDigests');

      return (0, _npmLodashArrayCompact['default'])(digests.map(function (digest) {
        var subdigest = digest.get('subdigests').sortBy('groupId')[0];

        var count = subdigest.get('count');
        if (count) {
          var kind = digest.get('groupId');
          var subkind = subdigest.get('groupId');
          return i18n.t('notification.' + kind + '.' + subkind, { count: count });
        }
      })).join(' | ');
    }).property('kindDigests'),
    notifications: (function () {
      var store = this.get('store');
      var notifierId = this.get('user.id');
      var expanded = this.get('expanded');
      var kind = this.get('kind');

      var filter = this.get('filter');
      filter = _extends({}, filter, filterMap[kind], { notifierId: notifierId });

      var serverFilter = (0, _milegacyUtilsKeyTransform.underscoreKeys)(filter);

      return (0, _milegacyUtilsCountable.countable)(store, 'notification', ['userId', 'notifierId'], { filter: serverFilter }, _extends({ isNew: false, isDeleted: false }, filter), ['-updatedAt'], { prefetch: expanded });
    }).property('expanded', 'kind'),
    actions: {
      toggleExpanded: function toggleExpanded() {
        this.toggleProperty('expanded');
      },
      deleteFromUser: function deleteFromUser() {
        var _this = this;

        var store = this.get('store');
        var notifierId = this.get('user.id');

        var filter = this.get('filter');
        filter = _extends({}, filter, { notifierId: notifierId });

        var serverFilter = (0, _milegacyUtilsKeyTransform.underscoreKeys)(filter);

        (0, _milegacyUtilsDestroyQuery['default'])(store, 'notification', { filter: serverFilter }, filter).then(function () {
          _this.unload();
          _this.sendAction('refresh');
        });
      }
    }
  });
});