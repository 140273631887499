define('milegacy/components/scroll-to', ['exports', 'ember', 'milegacy/utils/lazy-scroll'], function (exports, _ember, _milegacyUtilsLazyScroll) {

  var DURATION = 300;

  var $window = _ember['default'].$(window);

  exports['default'] = _ember['default'].Component.extend({
    offset: -60,
    margin: 100,
    visible: false,
    classNames: ['scroll-to-wrapper'],
    scroll: _ember['default'].on('click', function (event) {
      event.preventDefault();

      var target = this.get('target');
      _ember['default'].$(window).scrollTo(target, DURATION, { offset: { top: this.get('offset') } });
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      var $el = _ember['default'].$(this.get('target'));

      this.onScroll = function () {
        var top = $el.offset().top;
        var scroll = $window.scrollTop();
        var height = $el.height();
        var margin = _this.get('margin');
        var offset = _this.get('offset');

        var visible = scroll >= top + offset + margin && scroll <= top + offset + height;

        _this.set('visible', visible);
      };

      $window.on('scrollLazy', this.onScroll);
    },
    willDestroyElement: function willDestroyElement() {
      $window.off('scrollLazy', this.onScroll);
    }
  });
});