define('milegacy/utils/transition-lock', ['exports', 'ember'], function (exports, _ember) {
  exports.lockWindow = lockWindow;
  exports.lockTransition = lockTransition;
  exports.lock = lock;
  exports.triggerTransition = triggerTransition;

  var $ = _ember['default'].$;
  var $window = $(window);

  function lockWindow(cb) {
    var handler = function handler() {
      var value;

      var ask = function ask(message) {
        value = message;
      };

      cb(ask);

      return value;
    };

    $window.on('beforeunload', handler);
    return function () {
      $window.off('beforeunload', handler);
    };
  }

  function lockTransition(cb) {
    var handler = function handler(event) {
      var abort = function abort() {
        event.preventDefault();
      };

      var ask = function ask(message) {
        var okay = window.confirm(message);
        if (!okay) {
          abort();
        }
        return okay;
      };

      cb(ask);
    };

    $window.on('beforetransition', handler);
    return function () {
      $window.off('beforetransition', handler);
    };
  }

  function lock(cb) {
    var unlockWindow = lockWindow(cb);
    var unlockTransition = lockTransition(cb);

    return function () {
      unlockWindow();
      unlockTransition();
    };
  }

  function triggerTransition(transition) {
    var event = $.Event('beforetransition');
    event.transition = transition;
    $window.trigger(event);
    if (event.isDefaultPrevented()) {
      transition.abort();
    }
  }
});