define('milegacy/routes/friend', ['exports', 'ember', 'milegacy/routes/base'], function (exports, _ember, _milegacyRoutesBase) {
  exports['default'] = _milegacyRoutesBase['default'].extend({
    'page:title': 'MiFriends',
    'page:banner': 'MiFriends',
    'page:layout': 'app',
    model: function model(_ref) {
      var status = _ref.status;

      return _ember['default'].Object.create({
        status: status
      });
    }
  });
});