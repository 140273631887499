define("milegacy/templates/account/settings/deactivate", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 816
          }
        },
        "moduleName": "milegacy/templates/account/settings/deactivate.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "columns small-centered small-12 medium-8");
        var el2 = dom.createElement("h2");
        var el3 = dom.createTextNode("Deactivate Account");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        dom.setAttribute(el2, "class", "label");
        var el3 = dom.createTextNode("If you deactivate your account, you will lose all stories and contacts associated with your MiLegacy account. This includes access to any other users' legacies that have been shared with you.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        dom.setAttribute(el2, "class", "fieldset");
        var el3 = dom.createElement("fieldset");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "columns small-12");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "columns small-12");
        var el6 = dom.createElement("button");
        dom.setAttribute(el6, "type", "submit");
        dom.setAttribute(el6, "class", "field button button-danger");
        var el7 = dom.createTextNode("Deactivate Account");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 2]);
        var element1 = dom.childAt(element0, [0]);
        var element2 = dom.childAt(element1, [1, 0, 0]);
        var morphs = new Array(4);
        morphs[0] = dom.createElementMorph(element0);
        morphs[1] = dom.createAttrMorph(element1, 'disabled');
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [0, 0]), 0, 0);
        morphs[3] = dom.createAttrMorph(element2, 'disabled');
        return morphs;
      },
      statements: [["element", "action", ["deactivate", ["get", "password", ["loc", [null, [1, 325], [1, 333]]]], ["get", "model.user", ["loc", [null, [1, 334], [1, 344]]]]], ["on", "submit"], ["loc", [null, [1, 303], [1, 358]]]], ["attribute", "disabled", ["get", "model.user.isSaving", ["loc", [null, [1, 397], [1, 416]]]]], ["inline", "text-field", [], ["model", ["subexpr", "@mut", [["get", "model.user", ["loc", [null, [1, 485], [1, 495]]]]], [], []], "for", "passwordAuthorization", "value", ["subexpr", "@mut", [["get", "password", ["loc", [null, [1, 530], [1, 538]]]]], [], []], "type", "password", "placeholder", "Enter password to deactivate account"], ["loc", [null, [1, 466], [1, 607]]]], ["attribute", "disabled", ["subexpr", "not", [["get", "password", ["loc", [null, [1, 703], [1, 711]]]]], [], ["loc", [null, [1, 697], [1, 713]]]]]],
      locals: [],
      templates: []
    };
  })());
});