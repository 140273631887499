define('milegacy/components/user-group-list', ['exports', 'ember', 'npm:lodash', 'milegacy/utils/gattr'], function (exports, _ember, _npmLodash, _milegacyUtilsGattr) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    session: _ember['default'].inject.service(),
    userDigests: _ember['default'].computed.alias('digest.subdigests'),
    myNotifications: _ember['default'].computed.alias('partitionedNotifications.firstObject'),
    networkNotifications: _ember['default'].computed.alias('partitionedNotifications.lastObject'),
    partitionedNotifications: (function () {
      var digests = this.get('userDigests');

      if (!digests) {
        return [];
      }

      var userId = this.get('session.currentUser.id');
      var idGattr = (0, _milegacyUtilsGattr['default'])('groupId');

      return _npmLodash['default'].partition(digests.toArray(), function (g) {
        return idGattr(g) === userId;
      });
    }).property('userDigests')
  });
});