define('milegacy/services/devise', ['exports', 'ember', 'milegacy/config/environment'], function (exports, _ember, _milegacyConfigEnvironment) {

  var deviseConfig = _milegacyConfigEnvironment['default'].devise;

  exports['default'] = _ember['default'].Service.extend({
    host: deviseConfig.host,
    routes: deviseConfig.routes,
    confirm: function confirm(token) {
      var deferred = _ember['default'].$.getJSON(this.host + this.routes.confirm, { confirmation_token: token });

      return _ember['default'].RSVP.Promise.resolve(deferred);
    },
    requestPasswordReset: function requestPasswordReset(email) {
      var deferred = _ember['default'].$.post(this.host + this.routes.passwordReset, { user: { email: email } });

      return _ember['default'].RSVP.Promise.resolve(deferred);
    },
    resetPassword: function resetPassword(password, token) {
      var deferred = _ember['default'].$.ajax(this.host + this.routes.passwordReset, {
        type: 'PUT',
        data: { user: { password: password, reset_password_token: token }
        }
      });

      return _ember['default'].RSVP.Promise.resolve(deferred);
    }
  });
});