define('milegacy/utils/live-filter', ['exports', 'ember', 'milegacy/utils/sort-by', 'milegacy/utils/filter-by'], function (exports, _ember, _milegacyUtilsSortBy, _milegacyUtilsFilterBy) {
  exports['default'] = _ember['default'].ArrayProxy.extend({
    source: [],
    filterWith: _ember['default'].K,
    keys: [],
    init: function init() {
      this._super.apply(this, arguments);

      var keys = this.get('keys');
      var sort = this.get('sort');
      var filter = this.get('filterWith');

      var path = 'source.@each.{' + keys.join(',') + '}';

      _ember['default'].defineProperty(this, 'content', _ember['default'].computed(path, function () {
        var array = (0, _milegacyUtilsFilterBy['default'])(this.get('source'), filter);
        if (sort) {
          array = (0, _milegacyUtilsSortBy['default'])(array, sort);
        }
        return array;
      }));
    }
  });
});