define('milegacy/extensions/lodash', ['exports', 'ember', 'npm:lodash', 'milegacy/utils/sort-by', 'milegacy/utils/filter-by'], function (exports, _ember, _npmLodash, _milegacyUtilsSortBy, _milegacyUtilsFilterBy) {
  exports['default'] = function () {
    _npmLodash['default'].mixin({
      sortByEx: _milegacyUtilsSortBy['default'],
      filterByEx: _milegacyUtilsFilterBy['default'],
      // Collapse array neighbors into sub-arrays by a property
      collapseBy: function collapseBy(collection, iteratee, groupee) {
        var current = null;
        var group = null;
        var groups = [];
        _npmLodash['default'].forEach(collection, function (item) {
          var category = iteratee(item);
          if (!_ember['default'].isEqual(category, current)) {
            current = category;
            group = [];
            if (groupee) {
              category = groupee(item);
            }
            groups.push({ category: category, group: group });
          }
          group.push(item);
        });
        return groups;
      }
    });
  };
});