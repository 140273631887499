define("milegacy/templates/notification/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 19
            },
            "end": {
              "line": 1,
              "column": 257
            }
          },
          "moduleName": "milegacy/templates/notification/index.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "empty-list");
          var el2 = dom.createElement("h1");
          var el3 = dom.createTextNode("No Notifications");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          dom.setAttribute(el2, "class", "t-ui");
          var el3 = dom.createTextNode("Friend's stories associated with this");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("milestone will appear here.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 285
          }
        },
        "moduleName": "milegacy/templates/notification/index.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "inset");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        return morphs;
      },
      statements: [["block", "notification-list", [], ["digest", ["subexpr", "@mut", [["get", "model.digest", ["loc", [null, [1, 47], [1, 59]]]]], [], []], "filter", ["subexpr", "@mut", [["get", "model.filter", ["loc", [null, [1, 67], [1, 79]]]]], [], []], "refresh", ["subexpr", "route-action", ["refresh"], [], ["loc", [null, [1, 88], [1, 112]]]]], 0, null, ["loc", [null, [1, 19], [1, 279]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});