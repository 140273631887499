define('milegacy/components/upload-story', ['exports', 'ember', 'milegacy/utils/unwrap', 'milegacy/utils/transition-lock', 'milegacy/persistors/story', 'milegacy/models/file-upload', 'npm:lodash/collection/forEach'], function (exports, _ember, _milegacyUtilsUnwrap, _milegacyUtilsTransitionLock, _milegacyPersistorsStory, _milegacyModelsFileUpload, _npmLodashCollectionForEach) {

  var imageProcessOpts = {
    maxWidth: 1200,
    maxHeight: 1200,
    quality: 0.6
  };

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',
    isExpanded: false,
    visibilityOptions: [{ slug: 'anyone', name: 'Anyone', icon: 'world' }, { slug: 'network', name: 'My Network', icon: 'network' }, { slug: 'only_me', name: 'Only Me', icon: 'me' }],
    store: _ember['default'].inject.service('store'),
    persistor: (function () {
      var story = this.get('story');
      return _milegacyPersistorsStory['default'].create({ story: story });
    }).property('story'),
    milestoneOptions: (function () {
      return this.get('store').peekAll('milestone').sortBy('sort');
    }).property(),
    currentMilestone: (function () {
      var milestone = this.get('story.milestone');

      if (_ember['default'].isBlank((0, _milegacyUtilsUnwrap.unwrap)(milestone))) {
        return _ember['default'].Object.create({ slug: 'all', color: 'gray' });
      } else {
        return milestone;
      }
    }).property('story.milestone'),
    milestone: _ember['default'].computed('story.milestone', {
      get: function get() {
        return (0, _milegacyUtilsUnwrap.unwrap)(this.get('story.milestone'));
      },
      set: function set(key, value) {
        this.set('story.milestone', value);
        return value;
      }
    }),
    currentVisibility: (function () {
      return this.get('visibilityOptions').findBy('slug', this.get('story.audience'));
    }).property('story.audience'),
    click: function click() {
      this.set('isExpanded', true);
    },
    isPublishable: (function () {
      var hasTitle = _ember['default'].isPresent(this.get('story.title'));
      var hasBody = _ember['default'].isPresent(this.get('story.body'));
      var hasMilestone = _ember['default'].isPresent((0, _milegacyUtilsUnwrap.unwrap)(this.get('story.milestone')));
      var isExpanded = this.get('isExpanded');

      return isExpanded && hasTitle && hasBody && hasMilestone;
    }).property('story.title', 'story.body', 'story.milestone', 'isExpanded'),
    isDisabled: _ember['default'].computed.not('isPublishable'),
    addFile: function addFile(file) {
      var upload = _milegacyModelsFileUpload['default'].create({ file: file, imageProcessOpts: imageProcessOpts });
      var medias = this.get('story.media');

      var dup = medias.findBy('file.id', upload.get('id'));

      if (dup) {
        return;
      }

      var store = this.get('store');
      var story = this.get('story');
      var media = store.createRecord('media', {
        story: story,
        file: upload,
        url: file.name,
        mimetype: file.type,
        status: 'created'
      });

      upload.set('record', media);

      medias.addObject(media);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var i18n = this.get('i18n');
      this.unlock = (0, _milegacyUtilsTransitionLock.lock)(function (ask) {
        var story = _this.get('story');
        if (!story.get('isBlank') && ask(i18n.t('transition.uploader'))) {
          story.rollbackAttributes();
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.unlock();
    },
    actions: {
      addFiles: function addFiles(files) {
        var _this2 = this;

        (0, _npmLodashCollectionForEach['default'])(files, function (file) {
          _this2.addFile(file);
        });

        this.set('isExpanded', true);
      },
      upload: function upload() {
        this.sendAction('upload', this.get('persistor'));
      }
    }
  });
});