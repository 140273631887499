define('milegacy/routes/account/password/reset', ['exports', 'ember', 'milegacy/routes/public-base', 'milegacy/mixins/operation-proxy'], function (exports, _ember, _milegacyRoutesPublicBase, _milegacyMixinsOperationProxy) {
  exports['default'] = _milegacyRoutesPublicBase['default'].extend(_milegacyMixinsOperationProxy['default'], {
    devise: _ember['default'].inject.service('devise'),
    model: function model(params, transition) {
      return _ember['default'].Object.create({
        token: transition.queryParams.token,
        operation: this.createOperation()
      });
    },
    actions: {
      submit: function submit(model) {
        var _this = this;

        var flash = this.get('flashMessages');

        var promise = this.get('devise').resetPassword(model.get('password'), model.get('token')).then(function () {
          _ember['default'].run(function () {
            return flash.success(_this.get('i18n').t('flash.account.password.reset.success'));
          });
          return _this.transitionTo('login');
        })['catch'](function (data) {
          model.set('errors', data.responseJSON.errors);
        });

        this.updateOperation(promise);
      }
    }
  });
});