define('milegacy/utils/destroy-query', ['exports', 'milegacy/utils/filter-by'], function (exports, _milegacyUtilsFilterBy) {
  exports['default'] = destroyQuery;

  function destroyQuery(store, type, query, filter) {
    var adapter = store.adapterFor(type);
    var url = adapter.buildURL(type);

    return adapter.ajax(url, 'DELETE', { data: query }).then(function () {
      var records = store.peekAll(type);
      records = (0, _milegacyUtilsFilterBy['default'])(records, filter);
      records.forEach(function (record) {
        return record.unloadRecord();
      });
    });
  }
});