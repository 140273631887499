define('milegacy/components/horizontal-scroller', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['horizontal-scroller'],
    buffer: 12,
    interval: 96,
    onInsert: (function () {
      _ember['default'].run.scheduleOnce('afterRender', this, this.afterRender);
    }).on('didInsertElement'),
    afterRender: function afterRender() {
      var _this = this;

      var $list = this.$('.content');

      this.refreshScroll();

      $list.on('scroll', _npmLodash['default'].throttle(function () {
        return _this.refreshScroll();
      }, 200));
    },
    scrollLeft: 0,
    scrollRight: 0,
    refreshScroll: function refreshScroll() {
      var $list = this.$('.content');

      var scrollLeft = $list.scrollLeft();
      var scrollRight = $list[0].scrollWidth - $list.outerWidth() - scrollLeft;
      this.set('scrollLeft', scrollLeft);
      this.set('scrollRight', scrollRight);
    },
    farLeft: (function () {
      return this.get('scrollLeft') < this.get('buffer');
    }).property('scrollLeft'),
    farRight: (function () {
      return this.get('scrollRight') < this.get('buffer');
    }).property('scrollRight'),
    scrollBy: function scrollBy(magnitude) {
      var $list = this.$('.content');
      $list.animate({
        scrollLeft: '+=' + magnitude * this.get('interval')
      }, 100);
    },
    actions: {
      scrollLeft: function scrollLeft() {
        this.scrollBy(-1);
      },
      scrollRight: function scrollRight() {
        this.scrollBy(1);
      }
    }
  });
});