define('milegacy/components/text-field', ['exports', 'ember', 'milegacy/utils/unwrap', 'npm:autosize'], function (exports, _ember, _milegacyUtilsUnwrap, _npmAutosize) {

  var get = _ember['default'].get;
  var messageTranslations = {
    Body: {
      _blank: "Please Add a Description"
    }
  };

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['field-wrapper'],
    type: 'text',
    autosize: false,
    errors: (function () {
      return this.get('model.errors.' + this.get('for'));
    }).property('for', 'model.errors.[]'),
    error: (function () {
      var isSymbol = function isSymbol(message) {
        return message.charAt(0) === "_";
      };
      var message = this.get('errors.firstObject');

      if (message && _ember['default'].typeOf(message) !== 'string') {
        message = get(message, 'message');
      }

      if (_ember['default'].isPresent(message)) {
        var title = this.get('title');
        if (isSymbol(message)) {
          message = messageTranslations[title][message];
        } else {
          message = title + ' ' + message;
        }
        return message;
      }
    }).property('title', 'errors'),
    title: (function () {
      var i18n = this.get('i18n');
      var field = this.get('for');
      var model = (0, _milegacyUtilsUnwrap.unwrap)(this.get('model'));
      var type = get(model, 'constructor.modelName');

      return i18n.t('form.' + type + '.' + field);
    }).property('for'),
    transformedValue: _ember['default'].computed.alias('value'),
    didInsertElement: function didInsertElement() {
      if (this.get('autosize')) {
        (0, _npmAutosize['default'])(this.$('input, textarea'));
      }
    }
  });
});