define('milegacy/components/binary-popup', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    yesPrompt: 'Yes',
    noPrompt: 'No',
    prompt: '',
    actions: {
      yes: function yes() {
        this.get('modal').hide();
        this.sendAction('yes');
      },
      no: function no() {
        this.get('modal').hide();
        this.sendAction('no');
      }
    }
  });
});