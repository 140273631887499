define('milegacy/serializers/application', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _emberData['default'].JSONAPISerializer.extend({
    // Use Rails convention for polymorphism
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      var key = relationship.key;

      if (this._canSerialize(key)) {
        var belongsTo = snapshot.belongsTo(key);
        if (belongsTo !== undefined) {
          json.attributes = json.attributes || {};

          var _keysForPolymorphic = this.keysForPolymorphic(key);

          var id = _keysForPolymorphic.id;
          var type = _keysForPolymorphic.type;

          if (belongsTo) {
            json.attributes[id] = belongsTo.id;

            if (relationship.options.polymorphic) {
              json.attributes[type] = this.payloadKeyFromModelName(belongsTo.modelName);
            }
          }
        }
      }
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      return _defineProperty({}, snapshot.modelName, json.data.attributes);
    },
    keyForAttribute: function keyForAttribute(key) {
      return _ember['default'].String.underscore(key);
    },
    keyForRelationship: function keyForRelationship(key) {
      return _ember['default'].String.underscore(key);
    },
    keysForPolymorphic: function keysForPolymorphic(key) {
      key = _ember['default'].String.underscore(key);
      return { id: key + '_id', type: key + '_type' };
    },
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return _ember['default'].String.classify(modelName);
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      // Only send dirty attributes
      if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
        this._super(snapshot, json, key, attribute);
      }
    },
    normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, klass, payload, id, requestType) {
      payload = this._super(store, klass, payload, id, requestType);
      payload.data = payload.data[0] || null;
      return payload;
    }
  });
});