define('milegacy/components/search-results', ['exports', 'ember', 'milegacy/utils/model-type'], function (exports, _ember, _milegacyUtilsModelType) {

  var get = _ember['default'].get;

  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service('session'),
    classNameBindings: [':search-results'],
    currentResult: (function () {
      return this.get('results.firstObject');
    }).property('results', 'results.content'),
    users: (function () {
      var results = this.get('results');
      return results.filter(function (r) {
        return (0, _milegacyUtilsModelType['default'])(r) === 'user';
      });
    }).property('results', 'results.content'),
    myStories: (function () {
      var results = this.get('results');
      var userId = this.get('session.currentUser.id');
      return results.filter(function (r) {
        return (0, _milegacyUtilsModelType['default'])(r) === 'story' && get(r, 'userId') === userId;
      });
    }).property('results', 'results.content'),
    friendsStories: (function () {
      var results = this.get('results');
      var userId = this.get('session.currentUser.id');
      return results.filter(function (r) {
        return (0, _milegacyUtilsModelType['default'])(r) === 'story' && get(r, 'userId') !== userId;
      });
    }).property('results', 'results.content')
  });
});