define('milegacy/components/group-by', ['exports', 'ember', 'npm:lodash', 'milegacy/utils/gattr'], function (exports, _ember, _npmLodash, _milegacyUtilsGattr) {
  exports['default'] = _ember['default'].Component.extend({
    group: null,
    key: null,
    groups: (function () {
      var group = this.get('group');
      var key = this.get('key');
      var iteratee = this.get('iteratee');
      var groupee = this.get('groupee');

      if (!iteratee && group) {
        iteratee = (0, _milegacyUtilsGattr['default'])(group);
      }
      if (!groupee && key) {
        groupee = (0, _milegacyUtilsGattr['default'])(key);
      }

      var collection = this.get('collection').toArray();
      return _npmLodash['default'].chain(collection).collapseBy(iteratee, groupee).value();
    }).property('collection.[]', 'group', 'key')
  });
});