define('milegacy/transforms/hash', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      return _ember['default'].Object.create(serialized);
    },

    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });
});