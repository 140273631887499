define('milegacy/utils/error-conversion', ['exports', 'ember'], function (exports, _ember) {
  exports.errorsHashToArray = errorsHashToArray;

  function errorsHashToArray(errors) {
    var out = [];

    Object.keys(errors).forEach(function (key) {
      var messages = _ember['default'].makeArray(errors[key]);
      messages.forEach(function (message) {
        out.push({
          title: message,
          source: { pointer: 'data/attributes/' + key }
        });
      });
    });

    return out;
  }
});