define('milegacy/routes/notification', ['exports', 'ember', 'milegacy/routes/public-base'], function (exports, _ember, _milegacyRoutesPublicBase) {
  exports['default'] = _milegacyRoutesPublicBase['default'].extend({
    'page:title': 'MiNews',
    'page:banner': 'MiNews',
    'page:layout': 'app',
    model: function model(_ref) {
      var milestone_id = _ref.milestone_id;

      var store = this.get('store');
      var milestone = store.peekRecord('milestone', milestone_id);

      return _ember['default'].Object.create({
        milestone: milestone
      });
    }
  });
});