define('milegacy/components/video-lightbox', ['exports', 'ember', 'npm:video.js'], function (exports, _ember, _npmVideoJs) {

  _npmVideoJs['default'].addLanguage('en', {
    'The media could not be loaded, either because the server or network failed or because the format is not supported.': "This video is being processed, and should be ready soon!"
  });

  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':lightbox'],
    willDestroyElement: function willDestroyElement() {
      if (this.player) {
        this.player.dispose();
      }
    },
    didInsertElement: function didInsertElement() {
      this.player = (0, _npmVideoJs['default'])(this.$('video')[0]);
    },
    didRender: function didRender() {
      this.player.src(this.get('src'));
      this.player.poster(this.get('poster'));
    },
    poster: (function () {
      return this.get('media.signedUrls').thumb;
    }).property('media.signedUrls'),
    src: (function () {
      var urls = this.get('media.signedUrls');

      return [{ type: "video/webm", src: urls.webm }, { type: "video/mp4", src: urls.mp4 }];
    }).property('media.signedUrls')
  });
});