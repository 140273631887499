define('milegacy/components/story-widget', ['exports', 'ember', 'npm:lodash', 'npm:lodash/array/flatten', 'milegacy/utils/unwrap'], function (exports, _ember, _npmLodash, _npmLodashArrayFlatten, _milegacyUtilsUnwrap) {

  var get = _ember['default'].get;

  var DURATION = 300;
  var offset = {
    top: -60,
    left: -60
  };

  var scrollerClass = '.media-scroller .content';

  var $window = _ember['default'].$(window);

  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service('store'),
    session: _ember['default'].inject.service('session'),
    tagName: 'article',
    classNameBindings: [':story', 'story.isSaving:destroying'],
    isJournal: _ember['default'].computed.empty('story.media'),
    storyComments: _ember['default'].computed.filterBy('story.comments', 'isNew', false),
    mediaComments: (function () {
      var media = this.get('story.media');
      var comments = media.map(function (m) {
        return get(m, 'comments').toArray();
      });
      comments = (0, _npmLodashArrayFlatten['default'])(comments);
      return comments;
    }).property('story.media.[].content', 'story.media.content.@each.commentCount'),
    commentSource: (function () {
      if (this.get('currentMedia')) {
        return this.get('currentMedia.comments');
      }
      if (this.get('isJournal')) {
        return this.get('storyComments');
      }
      return this.get('mediaComments');
    }).property('storyComments', 'mediaComments', 'currentMedia.comments', 'isJournal'),
    comments: (function () {
      var comments = this.get('commentSource');
      return _npmLodash['default'].chain(comments).filterByEx({ isNew: false }).sortByEx(['-createdAt']).value();
    }).property('commentSource'),
    newComment: function newComment() {
      var store = this.get('store');
      var user = this.get('session.currentUser');
      var story = this.get('story');
      var currentMedia = this.get('currentMedia');

      var commentable = currentMedia ? currentMedia : story;

      return store.createRecord('comment', {
        user: user,
        commentable: commentable
      });
    },
    resetComment: function resetComment() {
      var comment = this.get('comment');
      if (comment) {
        comment.rollbackAttributes();
      }
    },
    updateComment: (function () {
      this.resetComment();
      this.set('comment', this.newComment());
    }).observes('isJournal', 'currentMedia').on('init'),
    willDestroyElement: function willDestroyElement() {
      this.resetComment();
    },
    click: function click() {
      this.sendAction('interact');
    },
    scrollTo: function scrollTo(media) {
      if (!this.get('isDestroying') && !this.get('isDestroyed')) {
        var $image = this.$('[data-media-id=' + media.get('id') + ']');
        var $scroll = this.$(scrollerClass);

        $window.scrollTo($image, DURATION, { offset: offset });
        $scroll.scrollTo($image, DURATION, { offset: offset });
      }
    },
    actions: {
      selectMedia: function selectMedia(media) {
        // If the media is already opened, hide the media.
        if (this.get('currentMedia') === media) {
          this.set('currentMedia', null);
        } else {
          this.set('currentMedia', media);

          if (media) {
            _ember['default'].run.scheduleOnce('afterRender', this, this.scrollTo, media);
          }
        }
      },
      comment: function comment(_comment) {
        var _this = this;

        _comment.save().then(function () {
          return _this.updateComment();
        });
      },
      remove: function remove() {
        if (this.get('removeAction')) {
          this.sendAction('removeAction');
        } else {
          (0, _milegacyUtilsUnwrap.unwrap)(this.get('story')).destroyRecord();
        }
      }
    }
  });
});