define('milegacy/initializers/ext', ['exports', 'milegacy/extensions/lodash'], function (exports, _milegacyExtensionsLodash) {
  exports.initialize = initialize;

  // Load all extensions

  function initialize() {
    (0, _milegacyExtensionsLodash['default'])();
  }

  exports['default'] = {
    name: 'ext',
    initialize: initialize
  };
});