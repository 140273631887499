define('milegacy/mixins/abridge', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    limit: 3,
    expanded: false,
    abridged: (function () {
      var isAbridged = this.get('isAbridged');
      var list = this.get('list');

      if (isAbridged) {
        var limit = this.get('limit');
        return list.slice(0, limit);
      } else {
        return list;
      }
    }).property('list', 'isAbridged'),
    isAbridged: (function () {
      var overLimit = this.get('overLimit');
      var expanded = this.get('expanded');

      return overLimit && !expanded;
    }).property('overLimit', 'expanded'),
    overLimit: (function () {
      var length = this.get('list.length');
      var limit = this.get('limit');

      return length > limit;
    }).property('list', 'limit')
  });
});