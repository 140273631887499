define('milegacy/utils/observable-from', ['exports', 'ember', 'npm:rx'], function (exports, _ember, _npmRx) {
  exports['default'] = observableFrom;

  // https://github.com/blesh/RxEmber/blob/master/ember-cli-rx/addon/helpers/observable-from.js

  function observableFrom(propName) {
    var arrIndex = propName.indexOf('.[]');
    var prop = propName;
    if (arrIndex !== -1) {
      prop = propName.substring(0, arrIndex);
    }

    return _ember['default'].computed(function () {
      var _this = this;

      return _npmRx['default'].Observable.create(function (observer) {
        var fn = function fn() {
          observer.onNext(_this.get(prop));
        };

        _this.addObserver(propName, fn);

        // this eager consumption is necessary due to lazy CP optimization preventing
        // observers from properly attaching unless the property is eagerly consumed
        _this.get(propName);

        return function () {
          _this.removeObserver(propName, fn);
        };
      });
    });
  }
});