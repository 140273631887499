define('milegacy/utils/update-query', ['exports', 'ember', 'milegacy/utils/filter-by'], function (exports, _ember, _milegacyUtilsFilterBy) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = updateQuery;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function updateQuery(store, type, query, filter, attributes) {
    var adapter = store.adapterFor(type);
    var url = adapter.buildURL(type);
    var body = _extends(_defineProperty({}, _ember['default'].String.underscore(type), attributes), query);

    return adapter.ajax(url, 'PATCH', { data: body }).then(function () {
      var records = store.peekAll(type);
      records = (0, _milegacyUtilsFilterBy['default'])(records, filter);

      var data = records.map(function (record) {
        return { id: record.get('id'), type: type, attributes: attributes };
      });

      store.push({ data: data });
    });
  }
});