define("milegacy/templates/components/media-image", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 98
          }
        },
        "moduleName": "milegacy/templates/components/media-image.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "image-scope", [], ["src", ["subexpr", "@mut", [["get", "media.displayUrl", ["loc", [null, [1, 18], [1, 34]]]]], [], []], "class", ["subexpr", "@mut", [["get", "class", ["loc", [null, [1, 41], [1, 46]]]]], [], []], "defaultSrc", ["subexpr", "@mut", [["get", "defaultImage", ["loc", [null, [1, 58], [1, 70]]]]], [], []], "imageClicked", ["subexpr", "@mut", [["get", "imageClicked", ["loc", [null, [1, 84], [1, 96]]]]], [], []]], ["loc", [null, [1, 0], [1, 98]]]]],
      locals: [],
      templates: []
    };
  })());
});