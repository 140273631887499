define("milegacy/templates/users/new", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 291
            },
            "end": {
              "line": 1,
              "column": 349
            }
          },
          "moduleName": "milegacy/templates/users/new.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["login.link"], [], ["loc", [null, [1, 331], [1, 349]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1117
          }
        },
        "moduleName": "milegacy/templates/users/new.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "sign-up columns small-centered small-12 medium-8 large-6 xlarge-5");
        var el2 = dom.createElement("p");
        dom.setAttribute(el2, "class", "t-h2 u-align-center banner");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        dom.setAttribute(el2, "class", "fieldset");
        var el3 = dom.createElement("fieldset");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "columns small-6");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "columns small-6 u-align-right");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "t-btn-borderless");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "columns small-12");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "columns small-12");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "columns small-12");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "columns small-12");
        var el6 = dom.createElement("button");
        dom.setAttribute(el6, "type", "submit");
        dom.setAttribute(el6, "class", "field button submit");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [0]);
        var element3 = dom.childAt(element2, [0]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [0]), 0, 0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createAttrMorph(element2, 'disabled');
        morphs[3] = dom.createMorphAt(dom.childAt(element3, [0]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element3, [1, 0]), 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [1, 0]), 0, 0);
        morphs[6] = dom.createMorphAt(dom.childAt(element2, [2, 0]), 0, 0);
        morphs[7] = dom.createMorphAt(dom.childAt(element2, [3, 0]), 0, 0);
        morphs[8] = dom.createMorphAt(dom.childAt(element2, [4, 0, 0]), 0, 0);
        return morphs;
      },
      statements: [["inline", "t", ["signup.banner"], [], ["loc", [null, [1, 121], [1, 142]]]], ["element", "action", ["submit", ["get", "model", ["loc", [null, [1, 170], [1, 175]]]]], ["on", "submit"], ["loc", [null, [1, 152], [1, 189]]]], ["attribute", "disabled", ["get", "model.isSaving", ["loc", [null, [1, 228], [1, 242]]]]], ["block", "link-to", ["login"], ["class", "button-link"], 0, null, ["loc", [null, [1, 291], [1, 361]]]], ["inline", "t", ["signup.link"], [], ["loc", [null, [1, 441], [1, 460]]]], ["inline", "text-field", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [1, 545], [1, 550]]]]], [], []], "for", "fullName", "value", ["subexpr", "@mut", [["get", "model.fullName", ["loc", [null, [1, 572], [1, 586]]]]], [], []], "placeholder", "First and last name"], ["loc", [null, [1, 526], [1, 622]]]], ["inline", "text-field", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [1, 700], [1, 705]]]]], [], []], "for", "email", "value", ["subexpr", "@mut", [["get", "model.email", ["loc", [null, [1, 724], [1, 735]]]]], [], []], "type", "email", "placeholder", "Email address"], ["loc", [null, [1, 681], [1, 778]]]], ["inline", "text-field", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [1, 856], [1, 861]]]]], [], []], "for", "password", "value", ["subexpr", "@mut", [["get", "model.password", ["loc", [null, [1, 883], [1, 897]]]]], [], []], "type", "password", "placeholder", "Password"], ["loc", [null, [1, 837], [1, 938]]]], ["inline", "t", ["signup.submit"], [], ["loc", [null, [1, 1047], [1, 1068]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});