define('milegacy/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {

  var Promise = _ember['default'].RSVP.Promise;

  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    session: _ember['default'].inject.service('session'),
    beforeModel: function beforeModel(transition) {
      var store = this.get('store');
      this._super(transition);
      return Promise.all([store.findAll('milestone'), this.get('session.currentUser')]);
    },
    model: function model() {
      return _ember['default'].Object.create({
        currentUser: this.get('session.currentUser')
      });
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this._super();
      this.refresh();
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});