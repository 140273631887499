define('milegacy/routes/notifications/index', ['exports', 'ember', 'milegacy/routes/base', 'milegacy/utils/countable', 'milegacy/utils/key-transform', 'milegacy/components/milestone-sidebar'], function (exports, _ember, _milegacyRoutesBase, _milegacyUtilsCountable, _milegacyUtilsKeyTransform, _milegacyComponentsMilestoneSidebar) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = _milegacyRoutesBase['default'].extend({
    filter: function filter(_ref) {
      var userId = _ref.userId;

      return { userId: userId, muted: false };
    },
    model: function model() {
      var store = this.get('store');
      var userId = this.get('session.currentUser.id');

      var filter = this.filter({ store: store, userId: userId });
      var serverFilter = (0, _milegacyUtilsKeyTransform.underscoreKeys)(filter);

      var digest = store.queryRecord('notification-digest', { filter: _extends({}, serverFilter, { group_by: 'notifier,kind,read' }) });

      var kind = 'friend_request';

      var friendNotifications = (0, _milegacyUtilsCountable.countable)(store, 'notification', ['userId'], { filter: _extends({}, serverFilter, { kind: kind }) }, _extends({}, filter, { isNew: false, isDeleted: false, kind: kind }), ['notifier_id'], { perPage: 100 });

      return _ember['default'].Object.create({
        filter: filter,
        digest: digest,
        friendNotifications: friendNotifications
      });
    },
    actions: {
      refresh: function refresh() {
        var store = this.get('store');
        (0, _milegacyComponentsMilestoneSidebar.refresh)(store, 'notification');

        // Refetch model
        this.model();
      }
    }
  });
});