define('milegacy/routes/friend/index', ['exports', 'ember', 'milegacy/routes/friends/index'], function (exports, _ember, _milegacyRoutesFriendsIndex) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = _milegacyRoutesFriendsIndex['default'].extend({
    'page:menu': _ember['default'].computed.alias('menu'),
    filter: function filter() {
      var status = this.modelFor('friend').get('status');
      var pending = status === 'pending';
      return _extends({}, this._super(), { pending: pending });
    },
    model: function model() {
      var status = this.modelFor('friend').get('status');

      this.set('menu', _ember['default'].Object.create({
        text: _ember['default'].String.capitalize(status) + ' Friends',
        icon: 'all',
        iconColor: 'gray'
      }));

      return this._super();
    }
  });
});