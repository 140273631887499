define('milegacy/routes/public-base', ['exports', 'ember', 'milegacy/utils/transition-lock'], function (exports, _ember, _milegacyUtilsTransitionLock) {
  exports['default'] = _ember['default'].Route.extend({
    pageService: _ember['default'].inject.service('page'),
    session: _ember['default'].inject.service(),
    actions: {
      didTransition: function didTransition() {
        var page = this.get('pageService');

        page.hideModals();

        var activeRoutes = this.get('router.router.state.handlerInfos').map(function (r) {
          return r.handler;
        });

        page.assignSources(activeRoutes);
      },
      willTransition: function willTransition(transition) {
        (0, _milegacyUtilsTransitionLock.triggerTransition)(transition);
      }
    }
  });
});