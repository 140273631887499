define('milegacy/utils/query-record-proxy', ['exports', 'ember', 'ember-data', 'milegacy/utils/expand-filter'], function (exports, _ember, _emberData, _milegacyUtilsExpandFilter) {
  exports.queryRecord = queryRecord;

  // Lazily queryRecord if it isn't already in the store

  function queryRecord(store, modelName, keys, query, filterWith) {
    var filter = (0, _milegacyUtilsExpandFilter['default'])(filterWith);
    var record = store.peekAll(modelName).filter(filter)[0];

    if (_ember['default'].isPresent(record)) {
      return _emberData['default'].PromiseObject.create({
        promise: _ember['default'].RSVP.Promise.resolve(record)
      });
    } else {
      return store.queryRecord(modelName, query);
    }
  }
});