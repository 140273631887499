define("milegacy/templates/components/user-group", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 44
            }
          },
          "moduleName": "milegacy/templates/components/user-group.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "scroll-to", [], ["target", ["subexpr", "@mut", [["get", "element", ["loc", [null, [1, 35], [1, 42]]]]], [], []]], ["loc", [null, [1, 16], [1, 44]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 51
            },
            "end": {
              "line": 1,
              "column": 133
            }
          },
          "moduleName": "milegacy/templates/components/user-group.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "friend-request-notification", [], ["notification", ["subexpr", "@mut", [["get", "friendRequest", ["loc", [null, [1, 118], [1, 131]]]]], [], []]], ["loc", [null, [1, 75], [1, 133]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 177
              },
              "end": {
                "line": 1,
                "column": 225
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "badge badge-avatar");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 232
              },
              "end": {
                "line": 1,
                "column": 315
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "avatar-image", [], ["src", ["subexpr", "@mut", [["get", "user.avatarUrl", ["loc", [null, [1, 284], [1, 298]]]]], [], []], "class", "avatar"], ["loc", [null, [1, 265], [1, 315]]]]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 451
                  },
                  "end": {
                    "line": 1,
                    "column": 487
                  }
                },
                "moduleName": "milegacy/templates/components/user-group.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("All Stories");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 500
                  },
                  "end": {
                    "line": 1,
                    "column": 553
                  }
                },
                "moduleName": "milegacy/templates/components/user-group.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Unread Stories & Comments");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 566
                  },
                  "end": {
                    "line": 1,
                    "column": 611
                  }
                },
                "moduleName": "milegacy/templates/components/user-group.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Unread Stories");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 624
                  },
                  "end": {
                    "line": 1,
                    "column": 675
                  }
                },
                "moduleName": "milegacy/templates/components/user-group.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Unread Comments");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child4 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 688
                  },
                  "end": {
                    "line": 1,
                    "column": 738
                  }
                },
                "moduleName": "milegacy/templates/components/user-group.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Followed Stories");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 427
                },
                "end": {
                  "line": 1,
                  "column": 751
                }
              },
              "moduleName": "milegacy/templates/components/user-group.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "x-option", [], ["value", "all"], 0, null, ["loc", [null, [1, 451], [1, 500]]]], ["block", "x-option", [], ["value", "unread"], 1, null, ["loc", [null, [1, 500], [1, 566]]]], ["block", "x-option", [], ["value", "new_story"], 2, null, ["loc", [null, [1, 566], [1, 624]]]], ["block", "x-option", [], ["value", "story_activity"], 3, null, ["loc", [null, [1, 624], [1, 688]]]], ["block", "x-option", [], ["value", "follow_story"], 4, null, ["loc", [null, [1, 688], [1, 751]]]]],
            locals: [],
            templates: [child0, child1, child2, child3, child4]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 383
              },
              "end": {
                "line": 1,
                "column": 810
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "select-wrapper");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "icon icon-mini icon-down");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            return morphs;
          },
          statements: [["block", "x-select", [], ["value", ["subexpr", "@mut", [["get", "kind", ["loc", [null, [1, 445], [1, 449]]]]], [], []]], 0, null, ["loc", [null, [1, 427], [1, 764]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 810
              },
              "end": {
                "line": 1,
                "column": 833
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "countString", ["loc", [null, [1, 818], [1, 833]]]]],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1015
              },
              "end": {
                "line": 1,
                "column": 1069
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "icon icon-gray icon-up");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1069
              },
              "end": {
                "line": 1,
                "column": 1117
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "icon icon-gray icon-down");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child6 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 1177
                },
                "end": {
                  "line": 1,
                  "column": 1254
                }
              },
              "moduleName": "milegacy/templates/components/user-group.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "icon icon-gray icon-remove-small");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1148
              },
              "end": {
                "line": 1,
                "column": 1400
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "modal-toggle", [], ["modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 1199], [1, 1204]]]]], [], []]], 0, null, ["loc", [null, [1, 1177], [1, 1271]]]], ["inline", "binary-popup", [], ["modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 1292], [1, 1297]]]]], [], []], "yes", "deleteFromUser", "prompt", ["subexpr", "t", ["notification.prompts.delete-group-notifications"], ["name", ["get", "user.fullName", ["loc", [null, [1, 1384], [1, 1397]]]]], ["loc", [null, [1, 1326], [1, 1398]]]]], ["loc", [null, [1, 1271], [1, 1400]]]]],
          locals: ["modal"],
          templates: [child0]
        };
      })();
      var child7 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1477
              },
              "end": {
                "line": 1,
                "column": 1597
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "notification-widget", [], ["notification", ["subexpr", "@mut", [["get", "notification", ["loc", [null, [1, 1567], [1, 1579]]]]], [], []], "refresh", ["subexpr", "@mut", [["get", "refresh", ["loc", [null, [1, 1588], [1, 1595]]]]], [], []]], ["loc", [null, [1, 1532], [1, 1597]]]]],
          locals: ["notification"],
          templates: []
        };
      })();
      var child8 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 1648
                },
                "end": {
                  "line": 1,
                  "column": 1826
                }
              },
              "moduleName": "milegacy/templates/components/user-group.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "empty-list");
              var el2 = dom.createElement("h1");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              dom.setAttribute(el2, "class", "t-ui");
              var el3 = dom.createTextNode("Try changing the filter to All Stories.");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 0]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", [["subexpr", "concat", ["notification.empty_states.", ["get", "kind", ["loc", [null, [1, 1749], [1, 1753]]]]], [], ["loc", [null, [1, 1712], [1, 1754]]]]], [], ["loc", [null, [1, 1708], [1, 1756]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1612
              },
              "end": {
                "line": 1,
                "column": 1837
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "unless", [["get", "notifications.length", ["loc", [null, [1, 1658], [1, 1678]]]]], [], 0, null, ["loc", [null, [1, 1648], [1, 1837]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child9 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1848
              },
              "end": {
                "line": 1,
                "column": 1906
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "countable-loader", [], ["records", ["subexpr", "@mut", [["get", "notifications", ["loc", [null, [1, 1891], [1, 1904]]]]], [], []]], ["loc", [null, [1, 1864], [1, 1906]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 133
            },
            "end": {
              "line": 1,
              "column": 1919
            }
          },
          "moduleName": "milegacy/templates/components/user-group.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "cell notification");
          var el2 = dom.createElement("div");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "titles");
          var el3 = dom.createElement("h2");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h4");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "actions");
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "#");
          dom.setAttribute(el3, "class", "action");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "action");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var element1 = dom.childAt(element0, [0]);
          var element2 = dom.childAt(element0, [1]);
          var element3 = dom.childAt(element2, [2]);
          var element4 = dom.childAt(element0, [2]);
          var element5 = dom.childAt(element4, [0]);
          var element6 = dom.childAt(fragment, [1]);
          var morphs = new Array(13);
          morphs[0] = dom.createMorphAt(element1, 0, 0);
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element2, [0]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
          morphs[4] = dom.createMorphAt(element3, 0, 0);
          morphs[5] = dom.createMorphAt(element3, 2, 2);
          morphs[6] = dom.createElementMorph(element5);
          morphs[7] = dom.createMorphAt(element5, 0, 0);
          morphs[8] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
          morphs[9] = dom.createAttrMorph(element6, 'class');
          morphs[10] = dom.createMorphAt(element6, 0, 0);
          morphs[11] = dom.createMorphAt(element6, 1, 1);
          morphs[12] = dom.createMorphAt(element6, 2, 2);
          return morphs;
        },
        statements: [["block", "if", [["get", "unread", ["loc", [null, [1, 183], [1, 189]]]]], [], 0, null, ["loc", [null, [1, 177], [1, 232]]]], ["block", "link-to", ["milestones", ["get", "user.id", ["loc", [null, [1, 256], [1, 263]]]]], [], 1, null, ["loc", [null, [1, 232], [1, 327]]]], ["content", "user.fullName", ["loc", [null, [1, 357], [1, 374]]]], ["block", "if", [["get", "expanded", ["loc", [null, [1, 389], [1, 397]]]]], [], 2, 3, ["loc", [null, [1, 383], [1, 840]]]], ["inline", "t", ["notification.activity"], [], ["loc", [null, [1, 849], [1, 878]]]], ["inline", "format-date", [["get", "digest.updatedAt", ["loc", [null, [1, 893], [1, 909]]]], "MMM D | h:mm A"], [], ["loc", [null, [1, 879], [1, 928]]]], ["element", "action", ["toggleExpanded"], [], ["loc", [null, [1, 963], [1, 990]]]], ["block", "if", [["get", "expanded", ["loc", [null, [1, 1021], [1, 1029]]]]], [], 4, 5, ["loc", [null, [1, 1015], [1, 1124]]]], ["block", "modal-context", [], [], 6, null, ["loc", [null, [1, 1148], [1, 1418]]]], ["attribute", "class", ["subexpr", "if", [["get", "expanded", ["loc", [null, [1, 1452], [1, 1460]]]], "", "u-hidden"], [], ["loc", [null, [1, 1447], [1, 1476]]]]], ["block", "date-group", [], ["records", ["subexpr", "@mut", [["get", "notifications", ["loc", [null, [1, 1499], [1, 1512]]]]], [], []]], 7, null, ["loc", [null, [1, 1477], [1, 1612]]]], ["block", "unless", [["get", "notifications.inProgress", ["loc", [null, [1, 1622], [1, 1646]]]]], [], 8, null, ["loc", [null, [1, 1612], [1, 1848]]]], ["block", "if", [["get", "expanded", ["loc", [null, [1, 1854], [1, 1862]]]]], [], 9, null, ["loc", [null, [1, 1848], [1, 1913]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1926
          }
        },
        "moduleName": "milegacy/templates/components/user-group.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "expanded", ["loc", [null, [1, 6], [1, 14]]]]], [], 0, null, ["loc", [null, [1, 0], [1, 51]]]], ["block", "if", [["get", "hasFriendRequest", ["loc", [null, [1, 57], [1, 73]]]]], [], 1, 2, ["loc", [null, [1, 51], [1, 1926]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});