define('milegacy/components/milestone-sidebar', ['exports', 'ember', 'milegacy/utils/sidebar'], function (exports, _ember, _milegacyUtilsSidebar) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports.refresh = refresh;

  var defaultFilters = {
    story: {},
    notification: { read: false, muted: false }
  };

  function refresh(store, subject) {
    var filter = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

    store.queryRecord(subject + '-digest', {
      filter: _extends({
        group_by: 'milestone'
      }, defaultFilters[subject], filter)
    });
  }

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    store: _ember['default'].inject.service(),
    subject: null,
    milestones: (function () {
      var store = this.get('store');
      var prompt = this.get('prompt');
      var link = this.get('link');
      var subject = this.get('subject');

      var filter;

      switch (subject) {
        case 'story':
          filter = { user_id: this.get('userId') };
          break;
        default:
          filter = {};
      }

      refresh(store, subject, filter);

      var milestones = store.peekAll('milestone').sortBy('sort');
      return (0, _milegacyUtilsSidebar.decorateMilestones)(store, milestones, link, prompt, subject);
    }).property('prompt')
  });
});