define('milegacy/utils/key-transform', ['exports', 'ember', 'npm:lodash/object/mapKeys'], function (exports, _ember, _npmLodashObjectMapKeys) {
  exports.underscoreKeys = underscoreKeys;
  exports.camelizeKeys = camelizeKeys;

  function underscoreKeys(obj) {
    return (0, _npmLodashObjectMapKeys['default'])(obj, function (_, key) {
      return _ember['default'].String.underscore(key);
    });
  }

  function camelizeKeys(obj) {
    return (0, _npmLodashObjectMapKeys['default'])(obj, function (_, key) {
      return _ember['default'].String.camelize(key);
    });
  }
});