define("milegacy/templates/components/friend-request-notification", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 36
            },
            "end": {
              "line": 1,
              "column": 97
            }
          },
          "moduleName": "milegacy/templates/components/friend-request-notification.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "badge badge-avatar");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 104
            },
            "end": {
              "line": 1,
              "column": 189
            }
          },
          "moduleName": "milegacy/templates/components/friend-request-notification.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "avatar-image", [], ["src", ["subexpr", "@mut", [["get", "friend.avatarUrl", ["loc", [null, [1, 156], [1, 172]]]]], [], []], "class", "avatar"], ["loc", [null, [1, 137], [1, 189]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 785
          }
        },
        "moduleName": "milegacy/templates/components/friend-request-notification.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "cell notification");
        var el2 = dom.createElement("div");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "titles");
        var el3 = dom.createElement("h2");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h4");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "actions stack-on-small");
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "class", "action button-link button-small");
        var el4 = dom.createElement("i");
        dom.setAttribute(el4, "class", "icon icon-outline icon-light-accent icon-accept");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" Accept");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "class", "action button-link button-small button-gray");
        var el4 = dom.createElement("i");
        dom.setAttribute(el4, "class", "icon icon-hollow icon-gray icon-remove-small");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" Decline");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [0]);
        var element2 = dom.childAt(element0, [1]);
        var element3 = dom.childAt(element0, [2]);
        var element4 = dom.childAt(element3, [0]);
        var element5 = dom.childAt(element3, [1]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(element1, 0, 0);
        morphs[1] = dom.createMorphAt(element1, 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [0]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element2, [2]), 0, 0);
        morphs[5] = dom.createAttrMorph(element4, 'disabled');
        morphs[6] = dom.createElementMorph(element4);
        morphs[7] = dom.createAttrMorph(element5, 'disabled');
        morphs[8] = dom.createElementMorph(element5);
        return morphs;
      },
      statements: [["block", "if", [["get", "notification.unread", ["loc", [null, [1, 42], [1, 61]]]]], [], 0, null, ["loc", [null, [1, 36], [1, 104]]]], ["block", "link-to", ["milestones", ["get", "user.id", ["loc", [null, [1, 128], [1, 135]]]]], [], 1, null, ["loc", [null, [1, 104], [1, 201]]]], ["content", "friend.fullName", ["loc", [null, [1, 231], [1, 250]]]], ["inline", "t", ["notification.friend.unread"], [], ["loc", [null, [1, 259], [1, 293]]]], ["inline", "format-date", [["get", "friendship.createdAt", ["loc", [null, [1, 316], [1, 336]]]], "MMM D | h:mm A"], [], ["loc", [null, [1, 302], [1, 355]]]], ["attribute", "disabled", ["get", "friendship.isSaving", ["loc", [null, [1, 441], [1, 460]]]]], ["element", "action", ["accept"], [], ["loc", [null, [1, 410], [1, 429]]]], ["attribute", "disabled", ["get", "friendship.isSaving", ["loc", [null, [1, 622], [1, 641]]]]], ["element", "action", ["decline"], [], ["loc", [null, [1, 590], [1, 610]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});