define('milegacy/components/drop-zone', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':drop-zone', 'dropping:dropping'],
    dragEnter: function dragEnter() {
      this.set('dropping', true);
    },
    dragLeave: function dragLeave(event) {
      if (event.target === this.get('element')) {
        this.set('dropping', false);
      }
    },
    dragEnd: function dragEnd(event) {
      if (event.target === this.get('element')) {
        this.set('dropping', false);
      }
    },
    dragOver: function dragOver(event) {
      event.preventDefault();
    },
    drop: function drop(event) {
      event.preventDefault();
      this.set('dropping', false);
      this.sendAction('onfile', event.dataTransfer.files);
    }
  });
});