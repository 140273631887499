define('milegacy/components/link-to-friend', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].LinkComponent.extend({
    session: _ember['default'].inject.service(),
    'current-when': 'friends users',
    active: _ember['default'].computed('attrs.params', '_routing.currentState', function () {
      var active = this._super();
      var routing = this.get('_routing');
      var currentState = routing.get('currentState');
      // if friends route or users/*/milestones route, make active
      // if users/currentUserId/milestones, false
      if (active) {
        var currentUserId = this.get('session.currentUser.id');
        var isActive = routing.isActiveForRoute([currentUserId], {}, 'user', currentState, true);
        if (isActive) {
          return false;
        }
      }
      return active;
    })
  });
});