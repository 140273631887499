define('milegacy/mixins/adapter-authorizer', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

  exports['default'] = _ember['default'].Mixin.create({
    ajax: function ajax(url, type, _options) {
      var options = _extends({}, _options, {
        authorization: this.get('authorization') });
      return this._super(url, type, options);
    },
    ajaxOptions: function ajaxOptions(url, type, _options) {
      var authorization = _options.authorization;

      var options = _objectWithoutProperties(_options, ['authorization']);

      var hash = this._super(url, type, options);

      if (_ember['default'].isPresent(authorization)) {
        (function () {
          var beforeSend = hash.beforeSend;

          hash.beforeSend = function (xhr) {
            xhr.setRequestHeader('X-Password-Authorization', authorization);
            if (beforeSend) {
              beforeSend(xhr);
            }
          };
        })();
      }

      return hash;
    },
    authorize: function authorize(password, body) {
      var _this = this;

      this.set('authorization', password);
      return body().then(function () {
        _this.set('authorization', null);
      });
    }
  });
});