define("milegacy/templates/application", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 22
            },
            "end": {
              "line": 1,
              "column": 204
            }
          },
          "moduleName": "milegacy/templates/application.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createAttrMorph(element0, 'class');
          morphs[2] = dom.createMorphAt(element0, 0, 0);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["inline", "partial", [["get", "page.layoutPath", ["loc", [null, [1, 65], [1, 80]]]]], [], ["loc", [null, [1, 55], [1, 82]]]], ["attribute", "class", ["concat", ["row content ", ["subexpr", "if", [["subexpr", "and", [["get", "menuModal.visible", ["loc", [null, [1, 116], [1, 133]]]], ["subexpr", "not", [["get", "page.search", ["loc", [null, [1, 139], [1, 150]]]]], [], ["loc", [null, [1, 134], [1, 151]]]]], [], ["loc", [null, [1, 111], [1, 152]]]], "menu-bar"], [], ["loc", [null, [1, 106], [1, 165]]]], " ", ["get", "page.layoutClass", ["loc", [null, [1, 168], [1, 184]]]]]]], ["content", "outlet", ["loc", [null, [1, 188], [1, 198]]]]],
        locals: ["menuModal"],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 238
          }
        },
        "moduleName": "milegacy/templates/application.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["content", "ember-load-remover", ["loc", [null, [1, 0], [1, 22]]]], ["block", "modal-context", [], [], 0, null, ["loc", [null, [1, 22], [1, 222]]]], ["content", "smoke-screen", ["loc", [null, [1, 222], [1, 238]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});