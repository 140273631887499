define("milegacy/templates/components/upload-story", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 317
              },
              "end": {
                "line": 1,
                "column": 635
              }
            },
            "moduleName": "milegacy/templates/components/upload-story.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("hr");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "text-field", [], ["model", ["subexpr", "@mut", [["get", "story", ["loc", [null, [1, 354], [1, 359]]]]], [], []], "for", "title", "value", ["subexpr", "@mut", [["get", "story.title", ["loc", [null, [1, 378], [1, 389]]]]], [], []], "placeholder", "Title your story.", "autofocus", true, "class", "story-title input-minimal"], ["loc", [null, [1, 335], [1, 472]]]], ["inline", "text-field", [], ["model", ["subexpr", "@mut", [["get", "story", ["loc", [null, [1, 495], [1, 500]]]]], [], []], "for", "body", "value", ["subexpr", "@mut", [["get", "story.body", ["loc", [null, [1, 518], [1, 528]]]]], [], []], "placeholder", "Write a description.", "autosize", true, "rows", 2, "type", "textarea", "class", "story-body input-minimal"], ["loc", [null, [1, 476], [1, 635]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 635
              },
              "end": {
                "line": 1,
                "column": 691
              }
            },
            "moduleName": "milegacy/templates/components/upload-story.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "story-prompt");
            var el2 = dom.createTextNode("Tell your story…");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("hr");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 781
              },
              "end": {
                "line": 1,
                "column": 953
              }
            },
            "moduleName": "milegacy/templates/components/upload-story.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "icon icon-outline icon-gray icon-add-media-small");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "control-label u-hide-small");
            var el2 = dom.createTextNode("Add Media");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 1027
                },
                "end": {
                  "line": 1,
                  "column": 1194
                }
              },
              "moduleName": "milegacy/templates/components/upload-story.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "control-label u-hide-small");
              var el2 = dom.createTextNode("Audience");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [0]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element4, 'class');
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["icon icon-outline icon-gray icon-", ["get", "currentVisibility.icon", ["loc", [null, [1, 1101], [1, 1123]]]], "-small"]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 977
              },
              "end": {
                "line": 1,
                "column": 1335
              }
            },
            "moduleName": "milegacy/templates/components/upload-story.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "control");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "modal-toggle", [], ["modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 1049], [1, 1054]]]]], [], []]], 0, null, ["loc", [null, [1, 1027], [1, 1211]]]], ["inline", "radio-popup", [], ["field", "audience", "value", ["subexpr", "@mut", [["get", "story.audience", ["loc", [null, [1, 1254], [1, 1268]]]]], [], []], "modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 1275], [1, 1280]]]]], [], []], "options", ["subexpr", "@mut", [["get", "visibilityOptions", ["loc", [null, [1, 1289], [1, 1306]]]]], [], []], "prompt", "Who can see this?"], ["loc", [null, [1, 1217], [1, 1335]]]]],
          locals: ["modal"],
          templates: [child0]
        };
      })();
      var child4 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 1403
                },
                "end": {
                  "line": 1,
                  "column": 1586
                }
              },
              "moduleName": "milegacy/templates/components/upload-story.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "control-label u-hide-small");
              var el2 = dom.createTextNode("Milestone");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [0]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element3, 'class');
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["icon icon-outline icon-", ["get", "currentMilestone.slug", ["loc", [null, [1, 1467], [1, 1488]]]], " icon-", ["get", "currentMilestone.color", ["loc", [null, [1, 1498], [1, 1520]]]]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 1742
                  },
                  "end": {
                    "line": 1,
                    "column": 2055
                  }
                },
                "moduleName": "milegacy/templates/components/upload-story.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("label");
                dom.setAttribute(el1, "class", "radio-wrapper radio-pill");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                var el3 = dom.createElement("i");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [0]);
                var element1 = dom.childAt(element0, [1]);
                var element2 = dom.childAt(element1, [0]);
                var morphs = new Array(5);
                morphs[0] = dom.createElementMorph(element0);
                morphs[1] = dom.createMorphAt(element0, 0, 0);
                morphs[2] = dom.createAttrMorph(element1, 'class');
                morphs[3] = dom.createAttrMorph(element2, 'class');
                morphs[4] = dom.createMorphAt(dom.childAt(element1, [2]), 0, 0);
                return morphs;
              },
              statements: [["element", "action", [["get", "modal.hide", ["loc", [null, [1, 1796], [1, 1806]]]]], ["preventDefault", false], ["loc", [null, [1, 1787], [1, 1829]]]], ["inline", "radio-button", [], ["name", "milestone", "groupValue", ["subexpr", "@mut", [["get", "milestone", ["loc", [null, [1, 1906], [1, 1915]]]]], [], []], "value", ["subexpr", "@mut", [["get", "option", ["loc", [null, [1, 1922], [1, 1928]]]]], [], []]], ["loc", [null, [1, 1863], [1, 1930]]]], ["attribute", "class", ["concat", ["pill pill-", ["get", "option.color", ["loc", [null, [1, 1954], [1, 1966]]]]]]], ["attribute", "class", ["concat", ["icon icon-", ["get", "option.slug", ["loc", [null, [1, 1992], [1, 2003]]]]]]], ["content", "option.title", ["loc", [null, [1, 2018], [1, 2034]]]]],
              locals: ["option"],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 1609
                },
                "end": {
                  "line": 1,
                  "column": 2070
                }
              },
              "moduleName": "milegacy/templates/components/upload-story.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("h3");
              dom.setAttribute(el1, "class", "u-align-center");
              var el2 = dom.createTextNode("Choose a milestone");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "radio-group pill-grid");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["block", "each", [["get", "milestoneOptions", ["loc", [null, [1, 1750], [1, 1766]]]]], [], 0, null, ["loc", [null, [1, 1742], [1, 2064]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1353
              },
              "end": {
                "line": 1,
                "column": 2081
              }
            },
            "moduleName": "milegacy/templates/components/upload-story.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "control");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "modal-toggle", [], ["modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 1425], [1, 1430]]]]], [], []]], 0, null, ["loc", [null, [1, 1403], [1, 1603]]]], ["block", "pop-up", [], ["modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 1625], [1, 1630]]]]], [], []], "class", "milestones-popup"], 1, null, ["loc", [null, [1, 1609], [1, 2081]]]]],
          locals: ["modal"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["multiple-nodes"]
          },
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 2365
            }
          },
          "moduleName": "milegacy/templates/components/upload-story.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "drop-prompt");
          var el2 = dom.createElement("p");
          dom.setAttribute(el2, "class", "t-ui");
          var el3 = dom.createElement("i");
          dom.setAttribute(el3, "class", "icon icon-add-media");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Drop Files Here");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("form");
          dom.setAttribute(el1, "class", "fieldset");
          var el2 = dom.createElement("fieldset");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "control-bar");
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "control");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "spacer");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "control publish");
          var el5 = dom.createElement("button");
          dom.setAttribute(el5, "type", "submit");
          dom.setAttribute(el5, "class", "button button-minimal submit");
          var el6 = dom.createElement("span");
          var el7 = dom.createTextNode("Publish");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("i");
          dom.setAttribute(el6, "class", "icon icon-outline icon-light-accent icon-arrow-right");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(element5, [0]);
          var element7 = dom.childAt(element6, [2]);
          var element8 = dom.childAt(element7, [4, 0]);
          var morphs = new Array(8);
          morphs[0] = dom.createElementMorph(element5);
          morphs[1] = dom.createAttrMorph(element6, 'disabled');
          morphs[2] = dom.createMorphAt(element6, 0, 0);
          morphs[3] = dom.createMorphAt(element6, 1, 1);
          morphs[4] = dom.createMorphAt(dom.childAt(element7, [0]), 0, 0);
          morphs[5] = dom.createMorphAt(element7, 1, 1);
          morphs[6] = dom.createMorphAt(element7, 2, 2);
          morphs[7] = dom.createAttrMorph(element8, 'disabled');
          return morphs;
        },
        statements: [["element", "action", ["upload"], ["on", "submit"], ["loc", [null, [1, 226], [1, 257]]]], ["attribute", "disabled", ["get", "persistor.isSaving", ["loc", [null, [1, 296], [1, 314]]]]], ["block", "if", [["get", "isExpanded", ["loc", [null, [1, 323], [1, 333]]]]], [], 0, 1, ["loc", [null, [1, 317], [1, 698]]]], ["inline", "upload-manager", [], ["medias", ["subexpr", "@mut", [["get", "story.media", ["loc", [null, [1, 722], [1, 733]]]]], [], []]], ["loc", [null, [1, 698], [1, 735]]]], ["block", "browse-button", [], ["onfile", "addFiles", "multiple", true], 2, null, ["loc", [null, [1, 781], [1, 971]]]], ["block", "modal-context", [], [], 3, null, ["loc", [null, [1, 977], [1, 1353]]]], ["block", "modal-context", [], [], 4, null, ["loc", [null, [1, 1353], [1, 2099]]]], ["attribute", "disabled", ["get", "isDisabled", ["loc", [null, [1, 2173], [1, 2183]]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 2379
          }
        },
        "moduleName": "milegacy/templates/components/upload-story.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "drop-zone", [], ["onfile", "addFiles", "class", ["subexpr", "concat", ["upload-zone upload-story", " ", ["subexpr", "if", [["get", "isExpanded", []], "expanded"], [], []], " "], [], []]], 0, null, ["loc", [null, [1, 0], [1, 2379]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});