define('milegacy/components/image-scope', ['exports', 'ember', 'milegacy/utils/lazy-scroll'], function (exports, _ember, _milegacyUtilsLazyScroll) {
  var SafeString = _ember['default'].Handlebars.SafeString;
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':image', 'methodClass'],
    method: 'cover',
    lazy: true,
    loadingSrc: '',
    defaultSrc: '',
    'data-original': _ember['default'].computed.alias('src'),
    placeholderStyle: (function () {
      var src = this.get('defaultSrc');
      if (_ember['default'].isPresent(src)) {
        return new SafeString('background-image: url("' + src + '");');
      } else {
        return new SafeString();
      }
    }).property('defaultSrc'),
    style: (function () {
      var src = this.get('src');

      if (this.get('lazy')) {
        src = this.get('loadingSrc');
      }

      if (_ember['default'].isPresent(src)) {
        return new SafeString('background-image: url("' + src + '");');
      } else {
        return new SafeString();
      }
    }).property('src'),
    methodClass: (function () {
      return 'image-' + this.get('method');
    }).property('method'),
    click: function click() {
      this.sendAction('imageClicked');
    },
    didInsertElement: function didInsertElement() {
      // Observe scroll on overflow: auto parent
      this.$().scrollParent().off('scroll', _milegacyUtilsLazyScroll['default']).on('scroll', _milegacyUtilsLazyScroll['default']);
    },
    didRender: function didRender() {
      _ember['default'].run.scheduleOnce('afterRender', this, this.afterRender);
    },
    afterRender: function afterRender() {
      if (this.get('lazy') && !this.get('isDestroying') && !this.get('isDestroyed') && _ember['default'].isPresent(this.get('data-original'))) {
        this.$('.original').lazyload({
          effect: function effect($el) {
            $el.css({ opacity: 0 }).animate({ opacity: 1 });
          },
          event: 'scrollLazy'
        });
      }
    }
  });
});