define("milegacy/templates/components/search-results", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 206
                  },
                  "end": {
                    "line": 1,
                    "column": 251
                  }
                },
                "moduleName": "milegacy/templates/components/search-results.hbs"
              },
              isEmpty: true,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 121
                },
                "end": {
                  "line": 1,
                  "column": 267
                }
              },
              "moduleName": "milegacy/templates/components/search-results.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "user-widget", [], ["user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [1, 226], [1, 230]]]]], [], []], "class", "user-small"], 0, null, ["loc", [null, [1, 206], [1, 267]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 57
              },
              "end": {
                "line": 1,
                "column": 285
              }
            },
            "moduleName": "milegacy/templates/components/search-results.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "search-result", [], ["link", "milestones", "models", ["subexpr", "@mut", [["get", "user.id", ["loc", [null, [1, 163], [1, 170]]]]], [], []], "result", ["subexpr", "@mut", [["get", "user", ["loc", [null, [1, 178], [1, 182]]]]], [], []], "current", ["subexpr", "@mut", [["get", "currentResult", ["loc", [null, [1, 191], [1, 204]]]]], [], []]], 0, null, ["loc", [null, [1, 121], [1, 285]]]]],
          locals: ["user"],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 385
                },
                "end": {
                  "line": 1,
                  "column": 700
                }
              },
              "moduleName": "milegacy/templates/components/search-results.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "cell");
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "titles");
              var el3 = dom.createElement("h2");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "actions");
              var el3 = dom.createElement("i");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [0]);
              var element4 = dom.childAt(element3, [1, 0]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element3, [0, 0]), 0, 0);
              morphs[1] = dom.createAttrMorph(element4, 'class');
              return morphs;
            },
            statements: [["content", "story.title", ["loc", [null, [1, 547], [1, 562]]]], ["attribute", "class", ["concat", ["icon icon-outline icon-", ["get", "story.milestone.color", ["loc", [null, [1, 629], [1, 650]]]], " icon-", ["get", "story.milestone.slug", ["loc", [null, [1, 660], [1, 680]]]]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 310
              },
              "end": {
                "line": 1,
                "column": 718
              }
            },
            "moduleName": "milegacy/templates/components/search-results.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "search-result", [], ["link", "story", "models", ["subexpr", "list", [["get", "story.userId", ["loc", [null, [1, 428], [1, 440]]]], ["get", "story.milestoneId", ["loc", [null, [1, 441], [1, 458]]]], ["get", "story.id", ["loc", [null, [1, 459], [1, 467]]]]], [], ["loc", [null, [1, 422], [1, 468]]]], "result", ["subexpr", "@mut", [["get", "story", ["loc", [null, [1, 476], [1, 481]]]]], [], []], "current", ["subexpr", "@mut", [["get", "currentResult", ["loc", [null, [1, 490], [1, 503]]]]], [], []]], 0, null, ["loc", [null, [1, 385], [1, 718]]]]],
          locals: ["story"],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 827
                },
                "end": {
                  "line": 1,
                  "column": 1167
                }
              },
              "moduleName": "milegacy/templates/components/search-results.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "cell");
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "titles");
              var el3 = dom.createElement("h2");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h3");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "actions");
              var el3 = dom.createElement("i");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [0]);
              var element1 = dom.childAt(element0, [0]);
              var element2 = dom.childAt(element0, [1, 0]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element1, [0]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
              morphs[2] = dom.createAttrMorph(element2, 'class');
              return morphs;
            },
            statements: [["content", "story.title", ["loc", [null, [1, 989], [1, 1004]]]], ["content", "story.author", ["loc", [null, [1, 1013], [1, 1029]]]], ["attribute", "class", ["concat", ["icon icon-outline icon-", ["get", "story.milestone.color", ["loc", [null, [1, 1096], [1, 1117]]]], " icon-", ["get", "story.milestone.slug", ["loc", [null, [1, 1127], [1, 1147]]]]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 743
              },
              "end": {
                "line": 1,
                "column": 1185
              }
            },
            "moduleName": "milegacy/templates/components/search-results.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "search-result", [], ["link", "story", "models", ["subexpr", "list", [["get", "story.userId", ["loc", [null, [1, 870], [1, 882]]]], ["get", "story.milestoneId", ["loc", [null, [1, 883], [1, 900]]]], ["get", "story.id", ["loc", [null, [1, 901], [1, 909]]]]], [], ["loc", [null, [1, 864], [1, 910]]]], "result", ["subexpr", "@mut", [["get", "story", ["loc", [null, [1, 918], [1, 923]]]]], [], []], "current", ["subexpr", "@mut", [["get", "currentResult", ["loc", [null, [1, 932], [1, 945]]]]], [], []]], 0, null, ["loc", [null, [1, 827], [1, 1185]]]]],
          locals: ["story"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 42
            },
            "end": {
              "line": 1,
              "column": 1210
            }
          },
          "moduleName": "milegacy/templates/components/search-results.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "search-results-group", [], ["title", "People", "results", ["subexpr", "@mut", [["get", "users", ["loc", [null, [1, 104], [1, 109]]]]], [], []]], 0, null, ["loc", [null, [1, 57], [1, 310]]]], ["block", "search-results-group", [], ["title", "Your Stories", "results", ["subexpr", "@mut", [["get", "myStories", ["loc", [null, [1, 363], [1, 372]]]]], [], []]], 1, null, ["loc", [null, [1, 310], [1, 743]]]], ["block", "search-results-group", [], ["title", "Friends’ Stories", "results", ["subexpr", "@mut", [["get", "friendsStories", ["loc", [null, [1, 800], [1, 814]]]]], [], []]], 2, null, ["loc", [null, [1, 743], [1, 1210]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1223
          }
        },
        "moduleName": "milegacy/templates/components/search-results.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "search-results-list");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element5, 0, 0);
        morphs[1] = dom.createMorphAt(element5, 1, 1);
        return morphs;
      },
      statements: [["content", "yield", ["loc", [null, [1, 33], [1, 42]]]], ["block", "if", [["get", "results", ["loc", [null, [1, 48], [1, 55]]]]], [], 0, null, ["loc", [null, [1, 42], [1, 1217]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});