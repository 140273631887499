define('milegacy/components/upload-manager', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':upload-manager', 'isEmpty:empty'],
    isEmpty: _ember['default'].computed.empty('medias'),
    actions: {
      removeMedia: function removeMedia(media) {
        this.get('medias').removeObject(media);
      }
    }
  });
});