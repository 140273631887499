define('milegacy/routes/account/password/request', ['exports', 'ember', 'milegacy/routes/public-base', 'milegacy/mixins/operation-proxy'], function (exports, _ember, _milegacyRoutesPublicBase, _milegacyMixinsOperationProxy) {
  exports['default'] = _milegacyRoutesPublicBase['default'].extend(_milegacyMixinsOperationProxy['default'], {
    devise: _ember['default'].inject.service('devise'),
    credentials: _ember['default'].inject.service('credentials'),
    model: function model() {
      return _ember['default'].Object.create({
        email: this.get('credentials.email'),
        operation: this.createOperation()
      });
    },
    actions: {
      submit: function submit(model) {
        var _this = this;

        var promise = this.get('devise').requestPasswordReset(model.get('email')).then(function () {
          return _this.transitionTo('account.password.sent');
        })['catch'](function (data) {
          model.set('errors', data.responseJSON.errors);
        });

        this.updateOperation(promise);
      }
    }
  });
});