define('milegacy/mixins/operation-proxy', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _ember['default'].Mixin.create({
    createOperation: function createOperation() {
      var promise = arguments.length <= 0 || arguments[0] === undefined ? _ember['default'].RSVP.Promise.resolve() : arguments[0];

      return this.set('operation', _emberData['default'].PromiseObject.create({ promise: promise }));
    },
    updateOperation: function updateOperation(promise) {
      this.set('operation.promise', promise);
    }
  });
});