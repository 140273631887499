define('milegacy/components/friend-list', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':friend-list', ':list'],
    actions: {
      unfriend: function unfriend(friendship) {
        var _this = this;

        friendship.destroyRecord().then(function () {
          _this.sendAction('refresh');
        });
      }
    }
  });
});