define('milegacy/models/file-upload', ['exports', 'ember', 'milegacy/uploader', 'milegacy/utils/process-image'], function (exports, _ember, _milegacyUploader, _milegacyUtilsProcessImage) {
  var _ref;

  var wURL = window.URL || window.webkitURL;
  var Promise = _ember['default'].RSVP.Promise;
  var hashAttrs = ['name', 'type', 'size'];

  exports['default'] = _ember['default'].Object.extend({
    file: null,
    record: null,
    isProgress: false,
    name: _ember['default'].computed.alias('file.name'),
    size: _ember['default'].computed.alias('file.size'),
    type: _ember['default'].computed.alias('file.type'),
    id: (_ref = function () {
      var _this = this;

      return hashAttrs.map(function (a) {
        return _this.get(a);
      }).join(':');
    }).property.apply(_ref, hashAttrs),
    progress: 0,
    progressStyle: (function () {
      return new _ember['default'].Handlebars.SafeString('width: ' + this.get('progress') * 100 + '%');
    }).property('progress'),
    blobUrl: (function () {
      var file = this.get('file');

      if (!_ember['default'].isNone(file)) {
        return wURL.createObjectURL(file);
      }
    }).property('file'),
    resizeBlob: null,
    resizeBlobUrl: (function () {
      var blob = this.get('resizeBlob');
      if (blob) {
        return wURL.createObjectURL(blob);
      }
    }).property('resizeBlob'),
    resized: (function () {
      var _this2 = this;

      var file = this.get('file');
      var isImage = this.get('isImage');

      if (!_ember['default'].isNone(file) && isImage) {
        (0, _milegacyUtilsProcessImage['default'])(file, this.imageProcessOpts, function (blob) {
          _this2.set('resizeBlob', blob);
        });
      }
    }).observes('file').on('init'),
    blob: (function () {
      return this.get('resizeBlob') || this.get('file');
    }).property('resizeBlob', 'file'),
    url: (function () {
      return this.get('resizeBlobUrl') || this.get('blobUrl');
    }).property('blobUrl', 'resizeBlobUrl'),
    displayUrl: (function () {
      if (this.get('isImage')) {
        return this.get('url');
      }
    }).property('url', 'isImage'),
    isImage: (function () {
      return this.get('kind') === 'image';
    }).property('kind'),
    kind: (function () {
      var match = this.get('type').match(/^(image|video|audio)\/.*$/);

      if (match) {
        return match[1];
      }
    }).property('type'),
    uploader: function uploader() {
      var record = this.get('record');
      var type = record.constructor.modelName;
      var adapter = record.get('store').adapterFor(type);
      var signUrl = adapter.buildURL(type, this.get('record.id')) + '/sign';

      return _milegacyUploader['default'].create({
        signAjax: function signAjax(payload) {
          return adapter.ajax(signUrl, 'POST', payload);
        }
      });
    },
    didUpload: function didUpload(response) {
      this.set('progress', 1.0);
      this.set('isProgress', false);
      this.set('isComplete', true);

      var uploadedUrl = _ember['default'].$(response).find('Location')[0].textContent;
      uploadedUrl = decodeURIComponent(uploadedUrl);
      this.set('awsUrl', uploadedUrl);
    },
    didError: function didError() {},
    upload: function upload() {
      var _this3 = this;

      if (this.get('isComplete')) {
        return;
      }

      var blob = this.get('blob');
      var uploader = this.uploader();

      this.set('progress', 0.0);
      this.set('isProgress', true);
      this.set('uploader', uploader);

      uploader.on('progress', function (e) {
        return _this3.set('progress', e.percent / 100);
      });
      uploader.on('didUpload', this.didUpload.bind(this));
      uploader.on('didError', this.didError.bind(this));

      var promise = new Promise(function (resolve, reject) {
        uploader.on('didUpload', resolve);
        uploader.on('didError', reject);
      });

      uploader.upload(blob);

      return promise;
    }
  });
});