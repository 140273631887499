define('milegacy/components/pop-up', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['pop-up'],
    classNameBindings: ['direction', 'modal.visible:visible'],
    direction: 'above',
    viewport: 'body',
    anchor: (function () {
      return '.toggle-' + this.get('modal.guid');
    }).property('modal.guid')
  });
});