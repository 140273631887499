define('milegacy/routes/friends/index', ['exports', 'ember', 'milegacy/routes/base', 'milegacy/utils/countable', 'milegacy/utils/key-transform', 'milegacy/components/friend-sidebar'], function (exports, _ember, _milegacyRoutesBase, _milegacyUtilsCountable, _milegacyUtilsKeyTransform, _milegacyComponentsFriendSidebar) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = _milegacyRoutesBase['default'].extend({
    filter: function filter() {
      var userId = this.get('session.currentUser.id');

      return { userId: userId };
    },
    model: function model() {
      var store = this.get('store');

      var filter = this.filter();
      var serverFilter = (0, _milegacyUtilsKeyTransform.underscoreKeys)(filter);

      var friendships = (0, _milegacyUtilsCountable.countable)(store, 'friendship', ['userId'], { filter: serverFilter }, _extends({ isNew: false, isDeleted: false }, filter), ['-pending', 'friend.fullName']);

      return _ember['default'].Object.create({
        friendships: friendships
      });
    },
    actions: {
      refresh: function refresh() {
        var store = this.get('store');
        (0, _milegacyComponentsFriendSidebar.refresh)(store, this.filter());

        // Refetch model
        this.model();
      }
    }
  });
});