define("milegacy/utils/memoize", ["exports"], function (exports) {
  exports["default"] = memoize;
  // Memoize the first non-null value
  // of a computed property's getter.

  function memoize(prop) {
    var getter = prop._getter;
    prop._getter = function (key) {
      this._memo = this._memo || {};
      var memo = this._memo[key];
      if (memo == null) {
        memo = getter.call(this, key);
        this._memo[key] = memo;
      }
      return memo;
    };
    return prop;
  }
});