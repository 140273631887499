define('milegacy/utils/unwrap', ['exports', 'ember'], function (exports, _ember) {
  exports.unwrap = unwrap;

  function unwrap(promise) {
    // Thenable
    if (promise && typeof promise.then === 'function') {
      // PromiseObject
      if ('content' in promise) {
        return _ember['default'].get(promise, 'content');
      }

      // RSVP.Promise
      if ('_result' in promise) {
        return promise._result;
      }
    }

    // Other
    return promise;
  }
});