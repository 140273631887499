define('milegacy/models/story', ['exports', 'ember', 'ember-data', 'milegacy/models/commentable', 'milegacy/utils/computed'], function (exports, _ember, _emberData, _milegacyModelsCommentable, _milegacyUtilsComputed) {
  exports['default'] = _milegacyModelsCommentable['default'].extend({
    title: _emberData['default'].attr('string'),
    body: _emberData['default'].attr('string'),
    audience: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('date'),
    milestone: _emberData['default'].belongsTo('milestone'),
    milestoneId: _ember['default'].computed.readOnly('milestone.id'),
    user: _emberData['default'].belongsTo('user'),
    userId: _ember['default'].computed.readOnly('user.id'),
    comments: _emberData['default'].hasMany('comment'),
    media: _emberData['default'].hasMany('media'),
    isBlank: (0, _milegacyUtilsComputed.isBlank)('title', 'body', 'media')
  });
});