define('milegacy/helpers/markdown', ['exports', 'ember', 'npm:marked'], function (exports, _ember, _npmMarked) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var SafeString = _ember['default'].Handlebars.SafeString;

  var markedOptions = {
    gfm: true,
    tables: true,
    breaks: false,
    pedantic: false,
    sanitize: true,
    smartLists: true,
    smartypants: true
  };

  exports['default'] = _ember['default'].Helper.helper(function (_ref, opts) {
    var _ref2 = _slicedToArray(_ref, 1);

    var body = _ref2[0];

    if (_ember['default'].typeOf(body) !== 'string') {
      return '';
    }

    return new SafeString((0, _npmMarked['default'])(body, _extends({}, markedOptions, opts)));
  });
});