define('milegacy/utils/process-image', ['exports'], function (exports) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = processImage;
  var FileAPI = window.FileAPI;

  var quality = 0.6;
  var type = 'image/jpeg';

  function processImage(file, opts, fn) {
    if (opts === undefined) opts = {};

    opts = _extends({
      type: type,
      quality: quality
    }, opts);

    FileAPI.Image.transform(file, { hd: opts }, true, function (error, img) {
      img.hd.toBlob(function (blob) {
        // Transfer name from file
        blob.name = file.name;
        fn(blob);
      }, opts.type, opts.quality);
    });
  }
});