define('milegacy/components/modal-toggle', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'a',
    href: '#',
    attributeBindings: ['href', 'disabled'],
    classNameBindings: [':modal-toggle', 'toggleClass'],
    toggleClass: (function () {
      return 'toggle-' + this.get('modal.guid');
    }).property('modal.guid'),
    click: function click(event) {
      event.preventDefault();
      this.get('modal.toggle')();
    }
  });
});