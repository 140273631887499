define('milegacy/utils/gattr', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = gattr;

  var get = _ember['default'].get;

  function gattr(attr) {
    return function (el) {
      return get(el, attr);
    };
  }
});