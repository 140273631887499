define('milegacy/utils/expand-filter', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = expandFilter;

  var get = _ember['default'].get;

  function expandFilter(filter) {
    if (_ember['default'].typeOf(filter) === 'function') {
      return filter;
    }

    var props = Object.keys(filter);

    return function (object) {
      return props.every(function (prop) {
        return get(filter, prop) === get(object, prop);
      });
    };
  }
});