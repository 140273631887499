define("milegacy/templates/layouts/modal", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 111
            },
            "end": {
              "line": 1,
              "column": 260
            }
          },
          "moduleName": "milegacy/templates/layouts/modal.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "src", "/assets/images/logo.svg");
          dom.setAttribute(el1, "alt", "MiLegacy Logo");
          dom.setAttribute(el1, "class", "logo");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "name t-h2");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "page.banner", ["loc", [null, [1, 238], [1, 253]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 332
              },
              "end": {
                "line": 1,
                "column": 602
              }
            },
            "moduleName": "milegacy/templates/layouts/modal.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "name t-h2");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [0]);
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createAttrMorph(element1, 'class');
            morphs[2] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["icon icon-down icon-bottom icon-", ["get", "page.menu.iconColor", ["loc", [null, [1, 424], [1, 443]]]]]]], ["attribute", "class", ["concat", ["icon icon-", ["get", "page.menu.iconColor", ["loc", [null, [1, 473], [1, 492]]]], " icon-", ["get", "page.menu.iconStyle", ["loc", [null, [1, 502], [1, 521]]]], " icon-", ["get", "page.menu.icon", ["loc", [null, [1, 531], [1, 545]]]]]]], ["content", "page.menu.text", ["loc", [null, [1, 577], [1, 595]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 278
            },
            "end": {
              "line": 1,
              "column": 625
            }
          },
          "moduleName": "milegacy/templates/layouts/modal.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("nav");
          dom.setAttribute(el1, "class", "item menu u-show-medium");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["block", "modal-toggle", [], ["modal", ["subexpr", "@mut", [["get", "menuModal", ["loc", [null, [1, 354], [1, 363]]]]], [], []], "class", "spacey"], 0, null, ["loc", [null, [1, 332], [1, 619]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 677
            },
            "end": {
              "line": 1,
              "column": 794
            }
          },
          "moduleName": "milegacy/templates/layouts/modal.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "icon icon-gray icon-remove-small");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 847
          }
        },
        "moduleName": "milegacy/templates/layouts/modal.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("header");
        dom.setAttribute(el1, "class", "navbar");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "navbar-content row");
        var el3 = dom.createElement("nav");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("nav");
        dom.setAttribute(el3, "class", "item spacer u-hide-medium");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [0]);
        var element4 = dom.childAt(element3, [0]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element4, 'class');
        morphs[1] = dom.createMorphAt(element4, 0, 0);
        morphs[2] = dom.createMorphAt(element3, 1, 1);
        morphs[3] = dom.createMorphAt(element3, 3, 3);
        morphs[4] = dom.createMorphAt(element2, 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["item home ", ["subexpr", "if", [["get", "page.menu", ["loc", [null, [1, 82], [1, 91]]]], "u-hide-medium"], [], ["loc", [null, [1, 77], [1, 109]]]]]]], ["block", "link-to", ["index"], ["class", "spacey"], 0, null, ["loc", [null, [1, 111], [1, 272]]]], ["block", "if", [["get", "page.menu", ["loc", [null, [1, 284], [1, 293]]]]], [], 1, null, ["loc", [null, [1, 278], [1, 632]]]], ["block", "link-to", ["milestones", ["get", "model.currentUser.id", ["loc", [null, [1, 701], [1, 721]]]]], ["class", "item item-icon"], 2, null, ["loc", [null, [1, 677], [1, 806]]]], ["inline", "partial", ["shared/toast"], [], ["loc", [null, [1, 812], [1, 838]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});