define('milegacy/components/countable-loader', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':countable-loader'],
    eventDebounce: 10,
    isLoading: _ember['default'].computed.alias('records.inProgress'),
    scrollable: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._setupScrollable();
      this._bindEvent('scroll');
      this._bindEvent('resize');
      this._checkIfInView();
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._unbindEvent('scroll');
      this._unbindEvent('resize');
    },

    _bindEvent: function _bindEvent(eventName) {
      var _this = this;

      this.get('_scrollable').on(eventName + '.' + _ember['default'].guidFor(this), function () {
        _ember['default'].run.debounce(_this, _this._checkIfInView, _this.get('eventDebounce'));
      });
    },

    _unbindEvent: function _unbindEvent(eventName) {
      this.get('_scrollable').off(eventName + '.' + _ember['default'].guidFor(this));
    },

    _checkIfInView: function _checkIfInView() {
      var $this = this.$();
      // Might be called afterRender
      if ($this == null) {
        return;
      }

      var selfOffset = $this.offset().top;
      var scrollable = this.get("_scrollable");
      var scrollableBottom = scrollable.height() + scrollable.scrollTop();

      var inView = selfOffset < scrollableBottom;

      if (inView) {
        this.get('records').fetchNext();
      }
    },

    _setupScrollable: function _setupScrollable() {
      var scrollable = this.get('scrollable');
      if (_ember['default'].typeOf(scrollable) === 'string') {
        var items = _ember['default'].$(scrollable);
        if (items.length === 1) {
          this.set('_scrollable', items.eq(0));
        } else if (items.length > 1) {
          throw new Error("Ember Countable: Multiple scrollable elements found for: " + scrollable);
        } else {
          throw new Error("Ember Countable: No scrollable element found for: " + scrollable);
        }
      } else if (scrollable == null) {
        this.set('_scrollable', _ember['default'].$(window));
      } else {
        throw new Error("Ember Countable: Scrollable must either be a css selector string or left empty to default to window");
      }
    },

    newRecords: _ember['default'].observer('records.length', function () {
      _ember['default'].run.scheduleOnce('afterRender', this, this._checkIfInView);
    })
  });
});