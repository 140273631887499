define('milegacy/routes/account/settings/credentials', ['exports', 'ember', 'milegacy/routes/base', 'milegacy/utils/unwrap'], function (exports, _ember, _milegacyRoutesBase, _milegacyUtilsUnwrap) {
  exports['default'] = _milegacyRoutesBase['default'].extend({
    'page:title': 'Account Credentials',
    'page:banner': 'Account Credentials',
    'page:menu': _ember['default'].computed.alias('menu'),
    model: function model() {
      this.set('menu', _ember['default'].Object.create({
        text: 'Account Credentials',
        icon: 'settings',
        iconColor: 'gray'
      }));

      var user = this.get('session.currentUser');

      return _ember['default'].Object.create({
        user: user
      });
    },
    saveUser: function saveUser(password, user, attrs) {
      user = (0, _milegacyUtilsUnwrap.unwrap)(user);
      user.rollbackAttributes({ except: attrs });
      return this.store.adapterFor('user').authorize(password, function () {
        return user.save();
      });
    },
    update: function update(password, user, attrs, field) {
      var _this = this;

      var flash = this.get('flashMessages');

      return this.saveUser(password, user, attrs).then(function () {
        flash.success(_this.get('i18n').t('flash.account.' + field + '.update.success'));
        _this.transitionTo('milestones', user.get('id'));
      });
    },
    actions: {
      updateEmail: function updateEmail(password, user) {
        this.update(password, user, ['email'], 'email');
      },
      updatePassword: function updatePassword(password, user) {
        this.update(password, user, ['password', 'passwordConfirmation'], 'password');
      },
      willTransition: function willTransition() {
        var user = this.get('controller.model.user');
        user = (0, _milegacyUtilsUnwrap.unwrap)(user);
        if (user) {
          user.rollbackAttributes();
        }
        return true;
      }
    }
  });
});