define("milegacy/templates/components/notification-list", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 135
                },
                "end": {
                  "line": 1,
                  "column": 293
                }
              },
              "moduleName": "milegacy/templates/components/notification-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "icon icon-gray icon-remove-all");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "control-label");
              var el2 = dom.createTextNode("Delete All");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 85
              },
              "end": {
                "line": 1,
                "column": 420
              }
            },
            "moduleName": "milegacy/templates/components/notification-list.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "control");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "modal-toggle", [], ["modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 157], [1, 162]]]]], [], []], "tagName", "button", "class", "button-link"], 0, null, ["loc", [null, [1, 135], [1, 310]]]], ["inline", "binary-popup", [], ["modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 337], [1, 342]]]]], [], []], "yes", "deleteAll", "prompt", "Are you sure you want to delete all notifications?"], ["loc", [null, [1, 316], [1, 420]]]]],
          locals: ["modal"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["multiple-nodes", "wrong-type"]
          },
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 507
            }
          },
          "moduleName": "milegacy/templates/components/notification-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "list-bar control-bar");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "spacer");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "modal-context", [], [], 0, null, ["loc", [null, [1, 85], [1, 438]]]], ["inline", "user-group-list", [], ["digest", ["subexpr", "@mut", [["get", "digest", ["loc", [null, [1, 469], [1, 475]]]]], [], []], "filter", ["subexpr", "@mut", [["get", "filter", ["loc", [null, [1, 483], [1, 489]]]]], [], []], "refresh", ["subexpr", "@mut", [["get", "refresh", ["loc", [null, [1, 498], [1, 505]]]]], [], []]], ["loc", [null, [1, 444], [1, 507]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 515
              },
              "end": {
                "line": 1,
                "column": 583
              }
            },
            "moduleName": "milegacy/templates/components/notification-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("h3");
            dom.setAttribute(el1, "class", "u-align-center");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            return morphs;
          },
          statements: [["content", "x-loader", ["loc", [null, [1, 566], [1, 578]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 583
              },
              "end": {
                "line": 1,
                "column": 600
              }
            },
            "moduleName": "milegacy/templates/components/notification-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "yield", ["loc", [null, [1, 591], [1, 600]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 507
            },
            "end": {
              "line": 1,
              "column": 607
            }
          },
          "moduleName": "milegacy/templates/components/notification-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "digest.isPending", ["loc", [null, [1, 521], [1, 537]]]]], [], 0, 1, ["loc", [null, [1, 515], [1, 607]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 614
          }
        },
        "moduleName": "milegacy/templates/components/notification-list.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "digest.subdigests", ["loc", [null, [1, 6], [1, 23]]]]], [], 0, 1, ["loc", [null, [1, 0], [1, 614]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});