define('milegacy/mixins/unauthorized-adapter', ['exports', 'ember', 'milegacy/errors/unauthorized'], function (exports, _ember, _milegacyErrorsUnauthorized) {
  exports['default'] = _ember['default'].Mixin.create({
    session: _ember['default'].inject.service('session'),
    handleResponse: function handleResponse(status, headers, payload) {
      if (status === 401) {
        this.get('session').invalidate();
        return new _milegacyErrorsUnauthorized['default'](payload && payload.error);
      } else {
        for (var _len = arguments.length, args = Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
          args[_key - 3] = arguments[_key];
        }

        return this._super.apply(this, [status, headers, payload].concat(args));
      }
    }
  });
});