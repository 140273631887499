define('milegacy/mixins/rollback', ['exports', 'ember'], function (exports, _ember) {

  var expandAttributes = function expandAttributes(record, options) {
    var list = _ember['default'].makeArray(options.only || options.except);

    if (options.only) {
      var attrs = [];
      record.eachAttribute(function (name) {
        if (!list.contains(name)) {
          attrs.push(name);
        }
      });
      return attrs;
    } else {
      return list;
    }
  };

  exports['default'] = _ember['default'].Mixin.create({
    rollbackAttributes: function rollbackAttributes(options) {
      if (options) {
        var memo = this.getProperties(expandAttributes(this, options));
        this._super();
        this.setProperties(memo);
      } else {
        this._super();
      }
    },
    save: function save() {
      if (this.get('isNew') || this.get('hasDirtyAttributes')) {
        return this._super.apply(this, arguments);
      } else {
        return _ember['default'].RSVP.Promise.resolve();
      }
    }
  });
});