define('milegacy/initializers/page', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(app) {
    app.inject('controller', 'page', 'service:page');
  }

  exports['default'] = {
    name: 'page',
    initialize: initialize
  };
});