define("milegacy/templates/components/radio-popup", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "modifiers",
            "modifiers": ["action"]
          },
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 343
            }
          },
          "moduleName": "milegacy/templates/components/radio-popup.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("label");
          dom.setAttribute(el1, "class", "radio-wrapper");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "icon icon-accent icon-check checked");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var element1 = dom.childAt(element0, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0, 0, 0);
          morphs[2] = dom.createAttrMorph(element1, 'class');
          morphs[3] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
          return morphs;
        },
        statements: [["element", "action", [["get", "modal.hide", ["loc", [null, [1, 85], [1, 95]]]]], ["preventDefault", false], ["loc", [null, [1, 76], [1, 118]]]], ["inline", "radio-button", [], ["name", ["subexpr", "@mut", [["get", "field", ["loc", [null, [1, 161], [1, 166]]]]], [], []], "groupValue", ["subexpr", "@mut", [["get", "value", ["loc", [null, [1, 178], [1, 183]]]]], [], []], "value", ["subexpr", "@mut", [["get", "option.slug", ["loc", [null, [1, 190], [1, 201]]]]], [], []]], ["loc", [null, [1, 141], [1, 203]]]], ["attribute", "class", ["concat", ["icon icon-gray icon-", ["get", "option.icon", ["loc", [null, [1, 287], [1, 298]]]]]]], ["content", "option.name", ["loc", [null, [1, 313], [1, 328]]]]],
        locals: ["option"],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 358
          }
        },
        "moduleName": "milegacy/templates/components/radio-popup.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "list-popup", [], ["modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 20], [1, 25]]]]], [], []], "options", ["subexpr", "@mut", [["get", "options", ["loc", [null, [1, 34], [1, 41]]]]], [], []], "prompt", ["subexpr", "@mut", [["get", "prompt", ["loc", [null, [1, 49], [1, 55]]]]], [], []]], 0, null, ["loc", [null, [1, 0], [1, 358]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});