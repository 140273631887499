define("milegacy/templates/milestone/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 141
            },
            "end": {
              "line": 1,
              "column": 235
            }
          },
          "moduleName": "milegacy/templates/milestone/index.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "upload-story", [], ["story", ["subexpr", "@mut", [["get", "model.newStory", ["loc", [null, [1, 198], [1, 212]]]]], [], []], "upload", "uploadStory"], ["loc", [null, [1, 177], [1, 235]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 271
            },
            "end": {
              "line": 1,
              "column": 347
            }
          },
          "moduleName": "milegacy/templates/milestone/index.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "story-widget", [], ["story", ["subexpr", "@mut", [["get", "story", ["loc", [null, [1, 340], [1, 345]]]]], [], []]], ["loc", [null, [1, 319], [1, 347]]]]],
        locals: ["story"],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 436
                },
                "end": {
                  "line": 1,
                  "column": 584
                }
              },
              "moduleName": "milegacy/templates/milestone/index.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "empty-list");
              var el2 = dom.createElement("h1");
              var el3 = dom.createTextNode("No stories yet…");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              dom.setAttribute(el2, "class", "t-ui");
              var el3 = dom.createTextNode("Publish a story to share with friends.");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 584
                  },
                  "end": {
                    "line": 1,
                    "column": 750
                  }
                },
                "moduleName": "milegacy/templates/milestone/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "empty-list");
                var el2 = dom.createElement("h1");
                var el3 = dom.createTextNode("No stories yet…");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("p");
                dom.setAttribute(el2, "class", "t-ui");
                var el3 = dom.createTextNode("This person hasn't shared a");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("br");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("story here with you.");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 750
                  },
                  "end": {
                    "line": 1,
                    "column": 888
                  }
                },
                "moduleName": "milegacy/templates/milestone/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "empty-list");
                var el2 = dom.createElement("h1");
                var el3 = dom.createTextNode("No public stories…");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("p");
                dom.setAttribute(el2, "class", "t-ui");
                var el3 = dom.createTextNode("Add this person to see any");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("br");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("network shared stories.");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 584
                },
                "end": {
                  "line": 1,
                  "column": 888
                }
              },
              "moduleName": "milegacy/templates/milestone/index.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "is-present", [["get", "model.friendship", ["loc", [null, [1, 606], [1, 622]]]]], [], ["loc", [null, [1, 594], [1, 623]]]]], [], 0, 1, ["loc", [null, [1, 584], [1, 888]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 404
              },
              "end": {
                "line": 1,
                "column": 895
              }
            },
            "moduleName": "milegacy/templates/milestone/index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "is-current-user", [["get", "model.user", ["loc", [null, [1, 459], [1, 469]]]]], [], ["loc", [null, [1, 442], [1, 470]]]]], [], 0, 1, ["loc", [null, [1, 436], [1, 895]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 368
            },
            "end": {
              "line": 1,
              "column": 906
            }
          },
          "moduleName": "milegacy/templates/milestone/index.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["get", "model.stories.length", ["loc", [null, [1, 414], [1, 434]]]]], [], 0, null, ["loc", [null, [1, 404], [1, 906]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 959
          }
        },
        "moduleName": "milegacy/templates/milestone/index.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "inset");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("hr");
        dom.setAttribute(el1, "class", "thick inset");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "story-container");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
        morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "profile-widget", [], ["user", ["subexpr", "@mut", [["get", "model.user", ["loc", [null, [1, 41], [1, 51]]]]], [], []], "friendship", ["subexpr", "@mut", [["get", "model.friendship", ["loc", [null, [1, 63], [1, 79]]]]], [], []], "save", "save", "refresh", "refresh"], ["loc", [null, [1, 19], [1, 111]]]], ["block", "if", [["subexpr", "is-current-user", [["get", "model.user", ["loc", [null, [1, 164], [1, 174]]]]], [], ["loc", [null, [1, 147], [1, 175]]]]], [], 0, null, ["loc", [null, [1, 141], [1, 242]]]], ["block", "date-group", [], ["records", ["subexpr", "@mut", [["get", "model.stories", ["loc", [null, [1, 293], [1, 306]]]]], [], []]], 1, null, ["loc", [null, [1, 271], [1, 362]]]], ["block", "unless", [["get", "model.stories.inProgress", ["loc", [null, [1, 378], [1, 402]]]]], [], 2, null, ["loc", [null, [1, 368], [1, 917]]]], ["inline", "countable-loader", [], ["records", ["subexpr", "@mut", [["get", "model.stories", ["loc", [null, [1, 944], [1, 957]]]]], [], []]], ["loc", [null, [1, 917], [1, 959]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});