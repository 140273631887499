define('milegacy/models/notification', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    read: _emberData['default'].attr('boolean'),
    muted: _emberData['default'].attr('boolean'),
    unread: _ember['default'].computed.not('read'),
    kind: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('date'),
    updatedAt: _emberData['default'].attr('date'),
    readAt: _emberData['default'].attr('date'),
    user: _emberData['default'].belongsTo('user'),
    userId: _ember['default'].computed.readOnly('user.id'),
    notifier: _emberData['default'].belongsTo('user'),
    notifierId: _ember['default'].computed.readOnly('notifier.id'),
    milestoneId: _ember['default'].computed.readOnly('notifiable.milestoneId'),
    notifiable: _emberData['default'].belongsTo('notifiable', { polymorphic: true }),
    notifiableType: _emberData['default'].attr('string', { readOnly: true }),
    type: (function () {
      return this.get('kind').dasherize();
    }).property('kind')
  });
});