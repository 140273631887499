define('milegacy/routes/account/confirm', ['exports', 'ember', 'milegacy/routes/public-base'], function (exports, _ember, _milegacyRoutesPublicBase) {
  exports['default'] = _milegacyRoutesPublicBase['default'].extend({
    devise: _ember['default'].inject.service('devise'),
    afterModel: function afterModel(model, transition) {
      var _this = this;

      this._super(model, transition);

      var flash = this.get('flashMessages');

      this.get('devise').confirm(transition.queryParams.token).then(function () {
        flash.success(_this.get('i18n').t('flash.account.confirm.success'));
      })['catch'](function () {
        flash.success(_this.get('i18n').t('flash.account.confirm.error'));
      })['finally'](function () {
        _this.transitionTo('index');
      });
    }
  });
});