define('milegacy/utils/lazy-scroll', ['exports', 'ember', 'npm:lodash/function/debounce', 'npm:lodash/function/throttle'], function (exports, _ember, _npmLodashFunctionDebounce, _npmLodashFunctionThrottle) {

  // Synthetic scroll event
  var $window = _ember['default'].$(window);
  var scrollEvents = 'scroll touchmove mousewheel DOMMouseScroll';
  var trigger = function trigger() {
    $window.trigger('scrollLazy');
  };

  // Trigger immediately when scrolling stops
  var debounceScroll = (0, _npmLodashFunctionDebounce['default'])(trigger, 20);
  // Periodically trigger during long scrolls
  var throttleScroll = (0, _npmLodashFunctionThrottle['default'])(trigger, 100);

  var lazyScroll = function lazyScroll() {
    debounceScroll();
    throttleScroll();
  };

  $window.on(scrollEvents, lazyScroll);

  exports['default'] = lazyScroll;
});