define('milegacy/utils/computed', ['exports', 'ember'], function (exports, _ember) {
  exports.isBlank = isBlank;

  function isBlank() {
    for (var _len = arguments.length, props = Array(_len), _key = 0; _key < _len; _key++) {
      props[_key] = arguments[_key];
    }

    return _ember['default'].computed.apply(_ember['default'], props.concat([function () {
      var _this = this;

      return props.every(function (prop) {
        return _ember['default'].isBlank(_this.get(prop));
      });
    }]));
  }
});