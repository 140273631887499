define('milegacy/utils/sort-by', ['exports', 'npm:lodash/collection/sortByOrder', 'milegacy/utils/parse-order', 'milegacy/utils/gattr'], function (exports, _npmLodashCollectionSortByOrder, _milegacyUtilsParseOrder, _milegacyUtilsGattr) {
  exports['default'] = sortBy;

  function sortBy(collection, orders) {
    var keys = [];
    var dirs = [];

    orders.forEach(function (order) {
      var _parseOrder = (0, _milegacyUtilsParseOrder['default'])(order);

      var desc = _parseOrder.desc;
      var key = _parseOrder.key;

      keys.push((0, _milegacyUtilsGattr['default'])(key));
      dirs.push(desc ? 'desc' : 'asc');
    });

    return (0, _npmLodashCollectionSortByOrder['default'])(collection, keys, dirs);
  }
});