define('milegacy/components/notification-widget', ['exports', 'ember', 'milegacy/utils/unwrap'], function (exports, _ember, _milegacyUtilsUnwrap) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    componentName: (function () {
      var type = this.get('notification.type');
      return type + '-notification';
    }).property('notification.type'),
    actions: {
      remove: function remove() {
        var _this = this;

        (0, _milegacyUtilsUnwrap.unwrap)(this.get('notification')).destroyRecord().then(function () {
          _this.sendAction('refresh');
        });
      },
      interact: function interact() {
        var _this2 = this;

        var notification = (0, _milegacyUtilsUnwrap.unwrap)(this.get('notification'));

        notification.set('read', true);
        notification.save().then(function () {
          _this2.sendAction('refresh');
        });
      }
    }
  });
});