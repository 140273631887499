define('milegacy/mixins/unload-recursive', ['exports', 'ember'], function (exports, _ember) {

  // Until https://github.com/emberjs/data/issues/3084
  // is fixed, have to unload all associations recursively.
  exports['default'] = _ember['default'].Mixin.create({
    unloadRecord: function unloadRecord() {
      var _this = this;

      var _ref = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var _ref$hasMany = _ref.hasMany;
      var hasMany = _ref$hasMany === undefined ? false : _ref$hasMany;

      if (hasMany) {
        this.constructor.eachRelationship(function (key, relationship) {
          if (relationship.kind === 'hasMany') {
            _this.get(key).forEach(function (record) {
              return record.unloadRecord({ hasMany: true });
            });
          }
        });
      }
      this._super();
    }
  });
});