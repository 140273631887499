define('milegacy/components/friend-request-notification', ['exports', 'ember', 'milegacy/utils/unwrap'], function (exports, _ember, _milegacyUtilsUnwrap) {
  exports['default'] = _ember['default'].Component.extend({
    friendship: _ember['default'].computed.readOnly('notification.notifiable'),
    friend: _ember['default'].computed.readOnly('friendship.friend'),
    actions: {
      accept: function accept() {
        var friendship = (0, _milegacyUtilsUnwrap.unwrap)(this.get('friendship'));
        var notification = (0, _milegacyUtilsUnwrap.unwrap)(this.get('notification'));

        friendship.set('pending', false);
        friendship.save().then(function () {
          return notification.destroyRecord();
        });
      },
      decline: function decline() {
        var friendship = (0, _milegacyUtilsUnwrap.unwrap)(this.get('friendship'));
        var notification = (0, _milegacyUtilsUnwrap.unwrap)(this.get('notification'));

        friendship = (0, _milegacyUtilsUnwrap.unwrap)(friendship);
        friendship.destroyRecord().then(function () {
          return notification.unloadRecord();
        });
      }
    }
  });
});