define('milegacy/adapters/application', ['exports', 'ember', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'milegacy/mixins/adapter-authorizer', 'milegacy/mixins/form-data-adapter', 'milegacy/mixins/unauthorized-adapter', 'milegacy/utils/error-conversion', 'milegacy/config/environment'], function (exports, _ember, _emberData, _emberSimpleAuthMixinsDataAdapterMixin, _milegacyMixinsAdapterAuthorizer, _milegacyMixinsFormDataAdapter, _milegacyMixinsUnauthorizedAdapter, _milegacyUtilsErrorConversion, _milegacyConfigEnvironment) {
  exports['default'] = _emberData['default'].JSONAPIAdapter.extend(_milegacyMixinsFormDataAdapter['default'], _emberSimpleAuthMixinsDataAdapterMixin['default'], _milegacyMixinsAdapterAuthorizer['default'], _milegacyMixinsUnauthorizedAdapter['default'], {
    authorizer: 'authorizer:application',
    host: _milegacyConfigEnvironment['default'].apiEndpoint,
    version: '1',
    headers: (function () {
      return {
        'Accept': 'application/vnd.api+json; version=' + this.get('version')
      };
    }).property('version'),
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      payload = _ember['default'].copy(payload);

      if (payload && _ember['default'].isPresent(payload.errors)) {
        payload.errors = (0, _milegacyUtilsErrorConversion.errorsHashToArray)(payload.errors);
      }

      return this._super(status, headers, payload, requestData);
    },
    pathForType: function pathForType(type) {
      return _ember['default'].String.underscore(this._super(type));
    },
    ajax: function ajax() {
      var _this = this;

      var promise = this._super.apply(this, arguments);

      this.set('inProgress', this.get('inProgress') + 1);
      promise['finally'](function () {
        _this.set('inProgress', _this.get('inProgress') - 1);
      });

      return promise;
    },
    inProgress: 0,
    page: _ember['default'].inject.service(),
    onInProgress: (function () {
      var inProgress = this.get('inProgress');
      this.set('page.loading', inProgress > 0);
    }).observes('inProgress').on('init')
  });
});