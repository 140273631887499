define('milegacy/services/page', ['exports', 'ember'], function (exports, _ember) {

  // Dynamically generate property aliases
  var properties = ['title', 'banner', 'menu', 'layout'];
  var aliases = {};
  properties.forEach(function (p) {
    return aliases[p] = _ember['default'].computed.alias('sources.' + p + '.page:' + p);
  });

  var hasDefinedPageProperty = function hasDefinedPageProperty(source, prop) {
    return !_ember['default'].isNone(source['page:' + prop]);
  };

  exports['default'] = _ember['default'].Service.extend({
    sources: _ember['default'].Object.create(),
    props: properties,
    setPageTitle: function setPageTitle(title) {
      window.document.title = title;
    },
    pageTitleChanged: (function () {
      this.setPageTitle(this.get('title'));
    }).observes('title'),
    activeModals: [],
    hideModals: function hideModals() {
      var modals = this.get('activeModals');
      modals.copy().forEach(function (m) {
        return m.hide();
      });
    },
    showSmoke: _ember['default'].computed.notEmpty('activeModals'),
    normalizedLayout: (function () {
      return this.get('layout') || 'default';
    }).property('layout'),
    layoutPath: (function () {
      return 'layouts/' + this.get('normalizedLayout');
    }).property('layout'),
    layoutClass: (function () {
      return this.get('normalizedLayout') + '-layout';
    }).property('layout'),
    assignSources: function assignSources(sources) {
      var _this = this;

      var remaining = this.get('props').copy();

      // Loop over sources in reverse
      var i = sources.length;
      while (i-- && remaining.length) {
        var source = sources[i];

        // Loop over all page properties
        remaining.copy().forEach(function (prop) {
          if (hasDefinedPageProperty(source, prop)) {
            _this.set('sources.' + prop, source);
            remaining.removeObject(prop);
          }
        });
      }

      // Reset all unassigned properties
      remaining.forEach(function (prop) {
        _this.set('sources.' + prop, undefined);
      });
    }
  }).reopen(aliases);
});