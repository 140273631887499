define('milegacy/transforms/file', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  var get = _ember['default'].get;

  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized;
    },

    serialize: function serialize(deserialized) {
      return deserialized && get(deserialized, 'blob');
    }
  });
});