define('milegacy/components/modal-context', ['exports', 'ember'], function (exports, _ember) {

  var bind = function bind(context, name) {
    return _ember['default'].run.bind(context, context[name]);
  };

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    visible: false,
    guid: (function () {
      return _ember['default'].guidFor(this);
    }).property(),
    modal: (function () {
      return _ember['default'].Object.create({
        guid: this.get('guid'),
        visible: this.get('visible'),
        show: bind(this, 'show'),
        hide: bind(this, 'hide'),
        toggle: bind(this, 'toggle')
      });
    }).property('guid', 'visible'),
    pageService: _ember['default'].inject.service('page'),
    show: function show() {
      this.toggle(true);
    },
    hide: function hide() {
      this.toggle(false);
    },
    toggle: function toggle() {
      var visible = arguments.length <= 0 || arguments[0] === undefined ? !this.get('visible') : arguments[0];

      var modals = this.get('pageService.activeModals');
      this.set('visible', visible);

      if (visible) {
        modals.addObject(this);
      } else {
        modals.removeObject(this);
      }
    }
  });
});