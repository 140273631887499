define('milegacy/components/notification-list', ['exports', 'ember', 'milegacy/utils/destroy-query', 'milegacy/utils/key-transform', 'milegacy/utils/unwrap'], function (exports, _ember, _milegacyUtilsDestroyQuery, _milegacyUtilsKeyTransform, _milegacyUtilsUnwrap) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':list', ':notification-list'],
    actions: {
      deleteAll: function deleteAll() {
        var _this = this;

        var store = this.get('digest.store');
        var filter = this.get('filter');
        var serverFilter = (0, _milegacyUtilsKeyTransform.underscoreKeys)(filter);

        var digest = (0, _milegacyUtilsUnwrap.unwrap)(this.get('digest'));

        (0, _milegacyUtilsDestroyQuery['default'])(store, 'notification', { filter: serverFilter }, filter).then(function () {
          digest.unloadRecord({ hasMany: true });
          _this.sendAction('refresh');
        });
      }
    }
  });
});