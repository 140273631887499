define('milegacy/routes/story/index', ['exports', 'ember', 'milegacy/routes/public-base'], function (exports, _ember, _milegacyRoutesPublicBase) {
  exports['default'] = _milegacyRoutesPublicBase['default'].extend({
    model: function model() {
      var userModel = this.modelFor('user');
      var user = userModel.get('user');
      var friendship = userModel.get('friendship');
      var story = this.modelFor('story');

      return _ember['default'].Object.create({
        story: story,
        stories: [story],
        user: user,
        friendship: friendship
      });
    }
  });
});