define('milegacy/app', ['exports', 'ember', 'ember-resolver', 'ember-load-initializers', 'milegacy/config/environment', 'milegacy/inflector'], function (exports, _ember, _emberResolver, _emberLoadInitializers, _milegacyConfigEnvironment, _milegacyInflector) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _milegacyConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _milegacyConfigEnvironment['default'].podModulePrefix,
    rootElement: '#root',
    Resolver: _emberResolver['default']
  });

  (0, _milegacyInflector['default'])();
  (0, _emberLoadInitializers['default'])(App, _milegacyConfigEnvironment['default'].modulePrefix);
  (0, _milegacyInflector['default'])();

  exports['default'] = App;
});