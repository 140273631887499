define("milegacy/templates/components/image-scope", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 134
          }
        },
        "moduleName": "milegacy/templates/components/image-scope.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "placeholder");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "original");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'style');
        morphs[1] = dom.createAttrMorph(element1, 'style');
        morphs[2] = dom.createAttrMorph(element1, 'data-original');
        return morphs;
      },
      statements: [["attribute", "style", ["get", "placeholderStyle", ["loc", [null, [1, 13], [1, 29]]]]], ["attribute", "style", ["get", "style", ["loc", [null, [1, 71], [1, 76]]]]], ["attribute", "data-original", ["get", "data-original", ["loc", [null, [1, 95], [1, 108]]]]]],
      locals: [],
      templates: []
    };
  })());
});