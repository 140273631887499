define('milegacy/uploader', ['exports', 'ember', 'ember-uploader/s3'], function (exports, _ember, _emberUploaderS3) {
  exports['default'] = _emberUploaderS3['default'].extend({
    upload: function upload(file) {
      var _this = this;

      this.set('isUploading', true);

      return this.sign(file).then(function (json) {
        var url = json.url;
        var formData = _this.setupFormData(file, json.fields);

        return _this.ajax(url, formData);
      });
    },

    sign: function sign(file) {
      var _this2 = this;

      var data = {
        name: file.name,
        type: file.type,
        size: file.size
      };

      var thenable = this.get('signAjax')({ data: data });

      return _ember['default'].RSVP.Promise.resolve(thenable).then(function () {
        return _this2.didSign.apply(_this2, arguments);
      })['catch'](function () {
        return _this2.didError.apply(_this2, arguments);
      });
    }
  });
});