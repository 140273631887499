define('milegacy/routes/story', ['exports', 'ember', 'milegacy/routes/public-base', 'milegacy/utils/sidebar'], function (exports, _ember, _milegacyRoutesPublicBase, _milegacyUtilsSidebar) {
  exports['default'] = _milegacyRoutesPublicBase['default'].extend({
    'page:menu': _ember['default'].computed.alias('menu'),
    model: function model(_ref) {
      var story_id = _ref.story_id;

      var store = this.get('store');
      var milestone = this.modelFor('milestone').get('milestone');
      var story = store.findRecord('story', story_id, { reload: true });

      this.set('menu', (0, _milegacyUtilsSidebar.decorateMilestone)(milestone));

      return story;
    },
    actions: {
      error: function error() {
        var flash = this.get('flashMessages');

        flash.danger(this.get('i18n').t('flash.story.notFound'));

        var userId = this.modelFor('user').get('userId');

        this.transitionTo('milestones', userId);
      }
    }
  });
});