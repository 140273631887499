define("milegacy/templates/story/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 170
            },
            "end": {
              "line": 1,
              "column": 246
            }
          },
          "moduleName": "milegacy/templates/story/index.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "story-widget", [], ["story", ["subexpr", "@mut", [["get", "story", ["loc", [null, [1, 239], [1, 244]]]]], [], []]], ["loc", [null, [1, 218], [1, 246]]]]],
        locals: ["story"],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 267
          }
        },
        "moduleName": "milegacy/templates/story/index.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "inset");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("hr");
        dom.setAttribute(el1, "class", "thick inset");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "story-container");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
        return morphs;
      },
      statements: [["inline", "profile-widget", [], ["user", ["subexpr", "@mut", [["get", "model.user", ["loc", [null, [1, 41], [1, 51]]]]], [], []], "friendship", ["subexpr", "@mut", [["get", "model.friendship", ["loc", [null, [1, 63], [1, 79]]]]], [], []], "save", "save", "refresh", "refresh"], ["loc", [null, [1, 19], [1, 111]]]], ["block", "date-group", [], ["records", ["subexpr", "@mut", [["get", "model.stories", ["loc", [null, [1, 192], [1, 205]]]]], [], []]], 0, null, ["loc", [null, [1, 170], [1, 261]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});