define('milegacy/utils/live-proxy', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].ObjectProxy.extend({
    key: 'id',
    cache: true,
    content: (function () {
      var match = this.get('collection').filterBy(this.get('key'), this.get('value')).get('firstObject');

      if (match != null && this.get('cache')) {
        this.set('content', match);
      }

      return match;
    }).property('collection.[]', 'key', 'value')
  });
});