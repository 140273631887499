define('milegacy/components/media-image', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    defaultImage: _ember['default'].computed('media.displayKind', 'small', function () {
      if (this.get('media.displayKind') === 'video') {
        if (this.get('small')) {
          return 'assets/images/small-video-play-icon.svg';
        } else {
          return 'assets/images/video-play-icon.svg';
        }
      }
    })
  });
});