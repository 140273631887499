define("milegacy/initializers/ember-i18n", ["exports", "milegacy/instance-initializers/ember-i18n"], function (exports, _milegacyInstanceInitializersEmberI18n) {
  exports["default"] = {
    name: _milegacyInstanceInitializersEmberI18n["default"].name,

    initialize: function initialize() {
      var application = arguments[1] || arguments[0]; // depending on Ember version
      if (application.instanceInitializer) {
        return;
      }

      _milegacyInstanceInitializersEmberI18n["default"].initialize(application);
    }
  };
});