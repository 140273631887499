define('milegacy/mixins/form-data-adapter', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    // Overwrite to change the request types on which Form Data is sent
    formDataTypes: ['POST', 'PUT', 'PATCH'],

    // Overwrite to flatten the form data by removing the root
    disableRoot: false,

    ajaxOptions: function ajaxOptions(url, type, options) {
      var data;

      if (options && 'data' in options) {
        data = options.data;
      }

      var hash = this._super.apply(this, arguments);

      if (typeof FormData !== 'undefined' && data && this.formDataTypes.indexOf(type) >= 0 && this._containsFile(data)) {
        hash.processData = false;
        hash.contentType = false;
        hash.data = this._getFormData(data);
      }

      return hash;
    },

    _containsFile: function _containsFile(data) {
      var _this = this;

      if (data instanceof window.File || data instanceof window.Blob) {
        return true;
      }

      if (_ember['default'].typeOf(data) !== 'object') {
        return false;
      }

      var keys = Object.keys(data);
      if (!keys.length) {
        return false;
      }

      return keys.some(function (key) {
        return _this._containsFile(data[key]);
      });
    },

    _getFormData: function _getFormData(data) {
      var formData = new FormData();
      var root = Object.keys(data)[0];

      Object.keys(data[root]).forEach(function (key) {
        var value = data[root][key];
        if (value !== undefined) {
          if (!this.get('disableRoot')) {
            key = root + "[" + key + "]";
          }

          var filename;

          if (value instanceof window.Blob) {
            filename = value.name;
          }

          formData.append(key, value, filename);
        }
      }, this);

      return formData;
    }
  });
});