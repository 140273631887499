define('milegacy/routes/notification/index', ['exports', 'ember', 'milegacy/utils/sidebar', 'milegacy/routes/notifications/index'], function (exports, _ember, _milegacyUtilsSidebar, _milegacyRoutesNotificationsIndex) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = _milegacyRoutesNotificationsIndex['default'].extend({
    'page:menu': _ember['default'].computed.alias('menu'),
    filter: function filter(attrs) {
      var milestoneId = this.modelFor('notification').get('milestone.id');
      return _extends({}, this._super(attrs), { milestoneId: milestoneId });
    },
    model: function model() {
      var milestone = this.modelFor('notification').get('milestone');
      this.set('menu', (0, _milegacyUtilsSidebar.decorateMilestone)(milestone));

      return this._super();
    }
  });
});